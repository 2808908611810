import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchServers, createServer, updateServer, deleteServer, selectServers, selectLoading, selectError } from "../monitor/ServersSlice";
import { Link } from "react-router-dom";
import Websites from "./Websites";
import { Button, TextField, Typography, Box, Grid, Dialog, DialogTitle, DialogContent, DialogActions, Select, MenuItem, InputLabel, FormControl, IconButton } from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPlus,

} from "@fortawesome/free-solid-svg-icons";

const Servers = () => {
    const dispatch = useDispatch();
    const servers = useSelector(selectServers);
    const loading = useSelector(selectLoading);
    const error = useSelector(selectError);

    const [selectedServer, setSelectedServer] = useState(null);
    const [showForm, setShowForm] = useState(false); // Toggle dialog visibility
    const [newServer, setNewServer] = useState({
        serverName: "",
        serverIP: "",
        serverDetails: {
            size: "",
            status: "Active", // Default value
            description: "",
        },

    });

    useEffect(() => {
        dispatch(fetchServers());
    }, []);

    const handleServerClick = (server) => {
        setSelectedServer((prevSelected) => (prevSelected === server._id ? null : server._id));
    };

    const handleCancel = () => {
        setShowForm(false);
        setNewServer({
            serverName: "",
            serverIP: "",
            serverDetails: {
                size: "",
                status: "Active",
                description: "",
            },

        });
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewServer((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleNestedInputChange = (e, parentKey) => {
        const { name, value } = e.target;
        setNewServer((prev) => ({
            ...prev,
            [parentKey]: {
                ...prev[parentKey],
                [name]: value,
            },
        }));
    };

    const handleAddServer = (e) => {
        e.preventDefault();
        if (newServer._id) {
            // If server has an ID, update it
            dispatch(updateServer({ id: newServer._id, updatedServer: newServer }));
        } else {
            dispatch(createServer(newServer));
        }
        setShowForm(false); // Hide the dialog
        setNewServer({
            serverName: "",
            serverIP: "",
            serverDetails: {
                size: "",
                status: "Active",
                description: "",
            },

        }); // Reset form fields
    };


    const handleEditServer = (server) => {
        setNewServer(server); // Set the server data to the form fields
        setShowForm(true); // Show the dialog
    };

    const handleDeleteServer = (serverId) => {
        dispatch(deleteServer(serverId)); // Dispatch delete action
    };

    if (loading && servers?.length == 0) return <p>Loading servers...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div>
            <div className="sections">
                <div className="d-flex justify-content-between">
                    <Typography variant="h4" gutterBottom>
                        Our Servers
                    </Typography>


                    <button
                        className="ml-auto btn btn-success transitionUp"
                        variant="contained"
                        color="success"
                        onClick={() => setShowForm(true)}
                        sx={{ fontWeight: "bold", borderRadius: 2 }}
                    >
                        <FontAwesomeIcon icon={faPlus} style={{ paddingRight: "5px" }} />
                        Add Server
                    </button>
                </div>

                <Dialog open={showForm} onClose={() => setShowForm(false)} maxWidth="sm" fullWidth>
                    <DialogTitle>{newServer._id ? "Edit Server" : "Add a New Server"}</DialogTitle>
                    <DialogContent>
                        <Box component="form" onSubmit={handleAddServer} noValidate>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Server Name"
                                name="serverName"
                                value={newServer.serverName}
                                onChange={handleInputChange}
                                required
                            />
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Server IP"
                                name="serverIP"
                                value={newServer.serverIP}
                                onChange={handleInputChange}
                                required
                            />

                            <Typography variant="h6" gutterBottom>
                                Server Details
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        label="Size"
                                        name="size"
                                        value={newServer.serverDetails.size}
                                        onChange={(e) => handleNestedInputChange(e, "serverDetails")}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth margin="normal" required>
                                        <InputLabel>Status</InputLabel>
                                        <Select
                                            label="Status"
                                            name="status"
                                            value={newServer.serverDetails.status}
                                            onChange={(e) => handleNestedInputChange(e, "serverDetails")}
                                        >
                                            <MenuItem value="Active">Active</MenuItem>
                                            <MenuItem value="due">Down</MenuItem>

                                            <MenuItem value="Maintenance">Maintenance</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        label="Description"
                                        name="description"
                                        value={newServer.serverDetails.description}
                                        onChange={(e) => handleNestedInputChange(e, "serverDetails")}
                                        required
                                    />
                                </Grid>
                            </Grid>



                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancel} color="secondary">
                            Cancel
                        </Button>
                        <Button type="submit" onClick={handleAddServer} variant="contained" color="primary">
                            {newServer._id ? "Update Server" : "Add Server"}
                        </Button>
                    </DialogActions>
                </Dialog>

                <div className="monitor-states-container-grid-4" style={{ marginTop: "20px" }}>
                    {servers?.map((server) => (
                        <div key={server._id} className="monitor-card-container" >
                            <div className="monitor-card-header position-relative" style={{ display: "flex", justifyContent: "space-between" }}


                            >
                                <div className="absolute-icons">
                                    <IconButton onClick={() => handleEditServer(server)} color="primary">
                                        <Edit />
                                    </IconButton>
                                    <IconButton onClick={() => handleDeleteServer(server._id)} color="secondary">
                                        <Delete />
                                    </IconButton>
                                </div>
                                <Link
                                    className="monitor-link"
                                    onClick={() => handleServerClick(server)}

                                >
                                    <div className="monitor-card-types "
                                        style={{
                                            backgroundColor: selectedServer === server._id ? "#41AFE250" : "",
                                            color: selectedServer === server._id ? "#1683FF" : "",
                                        }}>
                                        <h3>{server.serverName}</h3>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {selectedServer && (
                <div style={{ marginTop: "40px" }}>
                    <Websites projects={servers?.find(server => server._id == selectedServer)?.projects ?? []} serverId={selectedServer} />
                </div>
            )}
        </div>
    );
};

export default Servers;

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { createProject, deleteProject, updateProject } from "../monitor/websitesSlice";
import { fetchServers } from "../monitor/ServersSlice";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography, Box, Grid, MenuItem, Select, InputLabel, FormControl } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Edit, Delete } from "@mui/icons-material";

const Websites = ({ projects, serverId }) => {

    const [open, setOpen] = useState(false);


    const [newProject, setNewProject] = useState({
        projectName: "",
        server: serverId || "",
        projectAssignee: [{ role: "", name: "", email: "" }],
        projectStatus: "Active",
        projectType: "Website",
        urls: { main: "", admin: "" },
        credentials: "",
        appNameOnStore: "",
        appLinkOnStore: "",
    });


    const [selectedProject, setSelectedProject] = useState(null);
    const dispatch = useDispatch();

    console.log("Selected", selectedProject)
    // useEffect(() => {
    //     dispatch(fetchServers());
    // }, []);

    const handleOpen = (project = null) => {
        console.log("Selected", selectedProject)
        if (project) {
            setNewProject({
                projectName: project.projectName || "",
                server: serverId || "",
                projectAssignee: project.assignees || [{ role: "", name: "", email: "" }],
                projectStatus: project.projectStatus || "Active",
                projectType: project.projectType || "Website",
                urls: {
                    main: project.mainUrl || "",
                    admin: project.adminUrl || ""
                },
                credentials: project.credentials || "",
                appNameOnStore: project.appNameOnStore || "",
                appLinkOnStore: project.appLinkOnStore || "",
            });
            console.log(setNewProject.projectName)
            setSelectedProject(project);
        } else {
            setNewProject({
                projectName: "",
                server: serverId || "",
                projectAssignee: [{ role: "", name: "", email: "" }],
                projectStatus: "Active",
                projectType: "Website",
                urls: { main: "", admin: "" },
                credentials: "",
                appNameOnStore: "",
                appLinkOnStore: "",
            });
            setSelectedProject(null);
        }
        setOpen(true);
    };


    const handleClose = () => setOpen(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewProject((prev) => ({ ...prev, [name]: value }));

    };

    const handleAssigneeChange = (index, e) => {
        const { name, value } = e.target;
        const updatedAssignee = { ...newProject.projectAssignee[index], [name]: value };
        const updatedAssignees = [...newProject.projectAssignee];
        updatedAssignees[index] = updatedAssignee;

        setNewProject((prev) => ({ ...prev, projectAssignee: updatedAssignees }));
        console.log("newww", e.target.value)
    };


    const handleAddAssignee = () => {
        setNewProject((prev) => ({
            ...prev,
            projectAssignee: [...prev.projectAssignee, { role: "", name: "", email: "" }],
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (selectedProject) {
            console.log("After submit", newProject)
            dispatch(updateProject({ id: selectedProject.id, updatedProject: newProject })).then(() => {
                handleClose();
            });
        } else {
            // Add new project
            dispatch(createProject(newProject)).then(() => {
                handleClose();
            });
        }
    };

    const handleDelete = (projectId) => {
        dispatch(deleteProject(projectId));

    };

    const columns = [
        { field: "projectName", headerName: "Project Name", width: 200 },
        {
            field: "mainUrl",
            headerName: "Main URL",
            width: 250,
            renderCell: (params) => (

                <span>{params.value}</span>

            ),
        },
        {
            field: "adminUrl",
            headerName: "Admin URL",
            width: 250,
            renderCell: (params) => (
                <span>{params.value}</span>

            ),
        },
        { field: "projectStatus", headerName: "Status", width: 150 },
        { field: "projectType", headerName: "Type", width: 150 },
        { field: "credentials", headerName: "Credentials", width: 250 },
        { field: "appNameOnStore", headerName: "App Name On Store", width: 250 },
        {
            field: "appLinkOnStore",
            headerName: "App Store Link",
            width: 300,
            renderCell: (params) => (
                <span>{params.value}</span>
            ),
        },
        {
            field: "assignees",
            headerName: "Project Assignees",
            width: 500,
            renderCell: (params) => (
                <div className="betterFlex">
                    {params.value.map((assignee, index) => (
                        <div key={index}>
                            <strong>{assignee.role}:</strong> {assignee.name} |
                        </div>
                    ))}
                </div>
            ),
        },
        {
            field: "actions",
            headerName: "Actions",
            width: 150,
            renderCell: (params) => (
                <div>
                    <Edit
                        sx={{ cursor: "pointer", marginRight: 2 }}
                        onClick={() => handleOpen(params.row)}
                        color="primary"
                    />
                    <Delete
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleDelete(params.row.id)}
                        color="secondary"
                    />
                </div>
            ),
        },
    ];

    const rows = projects?.map((project, index) => ({
        id: project._id,
        projectName: project.projectName,
        mainUrl: project?.urls?.main,
        adminUrl: project?.urls?.admin,
        projectStatus: project.projectStatus,
        projectType: project.projectType,
        credentials: project.credentials,
        appNameOnStore: project.appNameOnStore,
        appLinkOnStore: project.appLinkOnStore,
        assignees: project.projectAssignee,
    }));

    return (
        <div style={{ height: 400, width: "100%" }}>
            <Button
                className="ml-auto btn btn-success transitionUp m-6"
                variant="contained"
                color="success"
                startIcon={<FontAwesomeIcon icon={faPlus} />}
                onClick={() => handleOpen()}
                sx={{ fontWeight: "bold", borderRadius: 2 }}
            >
                Add Project
            </Button>

            {/* Data Grid */}
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                sx={{
                    borderRadius: 2,
                    "& .odd-row": {
                        backgroundColor: "#f0f4ff",
                    },
                }}
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0
                        ? "odd-row"
                        : "even-row"
                }
            />

            {/* Add/Edit Project Dialog */}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{selectedProject ? "Edit Project" : "Add New Project"}</DialogTitle>
                <DialogContent>
                    <Box component="form" sx={{ mt: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Project Name"
                                    name="projectName"
                                    value={newProject.projectName}
                                    onChange={handleChange}

                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Main URL"
                                    name="urls.main"
                                    value={newProject?.urls?.main}
                                    onChange={(e) =>
                                        setNewProject((prev) => ({
                                            ...prev,
                                            urls: { ...prev.urls, main: e.target.value },
                                        }))
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Admin URL"
                                    name="urls.admin"
                                    value={newProject?.urls?.admin}
                                    onChange={(e) =>
                                        setNewProject((prev) => ({
                                            ...prev,
                                            urls: { ...prev.urls, admin: e.target.value },
                                        }))
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Credentials"
                                    name="credentials"
                                    value={newProject.credentials}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="App Name on Store"
                                    name="appNameOnStore"
                                    value={newProject.appNameOnStore}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="App Link on Store"
                                    name="appLinkOnStore"
                                    value={newProject.appLinkOnStore}
                                    onChange={handleChange}
                                />
                            </Grid>

                            {/* Project Assignees */}
                            {newProject.projectAssignee?.map((assignee, index) => (
                                <React.Fragment key={index}>
                                    <Grid item xs={4}>
                                        <FormControl fullWidth>
                                            <InputLabel>Role</InputLabel>
                                            <Select
                                                label="Role"
                                                name="role"
                                                value={assignee.role}
                                                onChange={(e) => handleAssigneeChange(index, e)}
                                            >
                                                <MenuItem value="Backend Dev">Backend Dev</MenuItem>
                                                <MenuItem value="Mobile Dev iOS">Mobile Dev iOS</MenuItem>
                                                <MenuItem value="Mobile Dev Android">Mobile Dev Android</MenuItem>
                                                <MenuItem value="Project Manager">Project Manager</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            fullWidth
                                            label="Name"
                                            name="name"
                                            value={assignee.name}
                                            onChange={(e) => handleAssigneeChange(index, e)}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            fullWidth
                                            label="Email"
                                            name="email"
                                            value={assignee.email}
                                            onChange={(e) => handleAssigneeChange(index, e)}
                                        />
                                    </Grid>
                                </React.Fragment>
                            ))}

                            <Grid item xs={12}>
                                <Button onClick={handleAddAssignee} variant="contained" color="primary">
                                    Add Assignee
                                </Button>
                            </Grid>

                            {/* Project Type*/}
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>Project Type</InputLabel>
                                    <Select
                                        label="Project Type"
                                        name="projectType"
                                        value={newProject.projectType}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="Website">Website</MenuItem>
                                        <MenuItem value="App">App</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            {/* Project Status */}
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>Project Status</InputLabel>
                                    <Select
                                        label="Project Status"
                                        name="projectStatus"
                                        value={newProject.projectStatus}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="Active">Active</MenuItem>
                                        <MenuItem value="Down">Down</MenuItem>
                                        <MenuItem value="Completed">Completed</MenuItem>
                                        <MenuItem value="Error">Error</MenuItem>
                                        <MenuItem value="On Hold">On Hold</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} variant="contained" color="primary">
                        {selectedProject ? "Update Project" : "Add Project"}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Websites;


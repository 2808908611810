// CreatePlanModal.js
import React from "react";
import Modal from "../../../layouts/modal/Modal";

const CreatePlanModal = ({
  createPlan,
  setCreatePlan,
  handleFormSubmit,
  planForm,
  setPlanForm,
  formErrors,
  planToEdit,
  assignees,
}) => {
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    createPlan && (
      <Modal
        classes="bg-secondary"
        onCloseModalClick={() => setCreatePlan(false)}
      >
        <form onSubmit={handleFormSubmit} onKeyPress={handleKeyPress}>
          <div className="support-plan-modal">
            <div>
              <h3
                className="title"
                style={{
                  margin: 0,
                  color: "#303030",
                  fontSize: 24,
                  padding: 0,
                  marginBottom: 5,
                }}
              >
                {planToEdit ? "Edit" : "New"} Support
              </h3>
              <p style={{ marginBottom: 10 }}>
                {planToEdit
                  ? "Edit the details of the support."
                  : "Create a new support by filling out the details below."}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 15,
              }}
            >
              <div className="form-group support-select">
                <label>Status*</label>
                <select
                  value={planForm.status}
                  onChange={(e) =>
                    setPlanForm({ ...planForm, status: e.target.value })
                  }
                >
                  <option value="">Select Status</option>
                  <option value="active">Paid</option>
                  <option value="inactive">Due</option>
                  <option value="new">Coming Soon</option>
                  <option value="archive">Archive</option>
                </select>
                {formErrors.status && (
                  <span className="text-danger input-error">
                    {formErrors.status}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label>Amount*</label>
                <input
                  type="text"
                  value={planForm.amount}
                  onChange={(e) =>
                    setPlanForm({ ...planForm, amount: e.target.value })
                  }
                  placeholder="Amount"
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
                {formErrors.amount && (
                  <span className="text-danger input-error">
                    {formErrors.amount}
                  </span>
                )}
              </div>
            </div>

            <div style={{ display: "flex", flexDirection: "row", gap: 15 }}>
              {/* Client Field */}
              <div className="form-group w-100">
                <label>Client*</label>
                <input
                  type="text"
                  value={planForm.client}
                  onChange={(e) =>
                    setPlanForm({ ...planForm, client: e.target.value })
                  }
                  placeholder="Client Name"
                />
                {formErrors.client && (
                  <span className="text-danger input-error">
                    {formErrors.client}
                  </span>
                )}
              </div>

              {/* Email Field */}
              <div className="form-group">
                <label>Email*</label>
                <input
                  type="text"
                  value={planForm.client_email}
                  onChange={(e) => {
                    // Update the client_email state to hold the raw string value
                    setPlanForm({
                      ...planForm,
                      client_email: e.target.value,
                    });
                  }}
                  placeholder="Email address (separate multiple with commas)"
                />
                {formErrors.client_email && (
                  <span className="text-danger input-error">
                    {formErrors.client_email}
                  </span>
                )}
              </div>
            </div>

            <div style={{ display: "flex", flexDirection: "row", gap: 15 }}>
              {/* Start Date Field */}
              <div className="form-group">
                <label>Start Date*</label>
                <input
                  type="date"
                  // value={formatDate(planForm.start_project_date)}
                  value={planForm.start_project_date}
                  onChange={(e) =>
                    setPlanForm({
                      ...planForm,
                      start_project_date: e.target.value,
                    })
                  }
                  placeholder="Start Date"
                />
                {formErrors.start_project_date && (
                  <span className="text-danger input-error">
                    {formErrors.start_project_date}
                  </span>
                )}
              </div>

              {/* End Date Field */}
              <div className="form-group">
                <label>End Date*</label>
                <input
                  type="date"
                  // value={formatDate(planForm.start_project_date)}
                  value={planForm.end_project_date}
                  onChange={(e) =>
                    setPlanForm({
                      ...planForm,
                      end_project_date: e.target.value,
                    })
                  }
                  placeholder="End Date"
                />
                {formErrors.end_project_date && (
                  <span className="text-danger input-error">
                    {formErrors.end_project_date}
                  </span>
                )}
              </div>
            </div>

            {/* Assignee Field */}
            <div className="form-group">
              <label>Assignee</label>
              <select
                value={planForm.assignee}
                onChange={(e) =>
                  setPlanForm({ ...planForm, assignee: e.target.value })
                }
              >
                <option value="" disabled>
                  Select Assignee
                </option>
                {assignees?.map((assignee) => (
                  <option key={assignee._id} value={assignee.name}>
                    {assignee.name}
                  </option>
                ))}
              </select>
              {formErrors.assignee && (
                <span className="text-danger input-error">
                  {formErrors.assignee}
                </span>
              )}
            </div>

            {/* Note Field */}
            <div className="form-group">
              <label>Note</label>
              <textarea
                value={planForm.note}
                onChange={(e) =>
                  setPlanForm({ ...planForm, note: e.target.value })
                }
                placeholder="Note"
              ></textarea>
            </div>

            {/* Submit Button */}
            <div className="d-flex">
              <button
                className="btn btn-lg btn-primary mx-auto w-100"
                style={{ borderRadius: 8 }}
                type="submit"
              >
                <h4>{planToEdit ? "Update" : "Create"}</h4>
              </button>
            </div>
          </div>
        </form>
      </Modal>
    )
  );
};

export default CreatePlanModal;

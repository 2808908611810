import "./Navbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBarsStaggered,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
import {
  TbLayoutSidebarLeftCollapseFilled,
  TbLayoutSidebarLeftExpandFilled,
} from "react-icons/tb";
import { HiMenu } from "react-icons/hi";
import { TbLayoutDashboardFilled } from "react-icons/tb";

function Navbar({
  toggleSidebar,
  toggleSidebarExpanded,
  sidebarOpen,
  sidebarRight,
  openSidebar,
}) {
  const location = useLocation();
  const isDashboardPage = location.pathname === "/";

  return (
    <div
      id="navbar"
      className={`${sidebarRight ? "sidebar-right" : ""} ${
        !sidebarOpen ? "sidebar-closed" : ""
      }`}
    >
      <div>
        <button
          className="btn icon-btn toggle-sidebar-expanded"
          onMouseEnter={() => {
            if (!sidebarOpen) {
              toggleSidebarExpanded();
            }
          }}
          onClick={() => {
            toggleSidebar();
            toggleSidebarExpanded();
          }}
        >
          {sidebarRight ? (
            sidebarOpen ? (
              <TbLayoutSidebarLeftExpandFilled color="#333333" size={25} />
            ) : (
              <HiMenu  color="#333333" size={25} />
            )
          ) : sidebarOpen ? (
            <TbLayoutSidebarLeftCollapseFilled color="#333333" size={25} />
          ) : (
            <HiMenu  color="#333333" size={25} />
          )}
        </button>

        <button
          className="btn-primary btn icon-btn toggle-sidebar-open"
          onClick={openSidebar}
        >
          <FontAwesomeIcon icon={faBarsStaggered} />
        </button>
      </div>
      {isDashboardPage && (
        <h3 className="ml-auto" style={{ color: "#333333", paddingRight: 10 }}>
          Welcome back,
        </h3>
      )}
      {!isDashboardPage && (
        <Link
          className="ml-auto btn transitionUp dashboardButton"
          to="/"
        >
          <TbLayoutDashboardFilled
            style={{ paddingRight: "8px", color: "inherit" }}
          />
          <span style={{ color: "inherit", paddingRight: 5 }}>Dashboard </span>
        </Link>
      )}
    </div>
  );
}

export default Navbar;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../utils/api"; // assuming api utility is the same
import { toast } from "react-toastify"; // Importing toastify

// Fetch all servers
export const fetchServers = createAsyncThunk(
    "servers/fetchServers",
    async (_, { dispatch }) => {
        try {
            const response = await api.get("/servers", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });

            return response.data;
        } catch (error) {
            console.error("Error fetching servers:", error);
            toast.error("Failed to fetch servers!"); // Error toast
            throw error;
        }
    }
);

// Create a new server
export const createServer = createAsyncThunk(
    "servers/createServer",
    async (newServer, { dispatch }) => {
        try {
            const response = await api.post("/servers", newServer, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            dispatch(fetchServers());
            toast.success("Server created successfully!"); // Success toast
            return response.data;
        } catch (error) {
            console.error("Error creating server:", error);
            toast.error("Failed to create server!"); // Error toast
            throw error;
        }
    }
);

// Delete a server by ID
export const deleteServer = createAsyncThunk(
    "servers/deleteServer",
    async (id, { dispatch }) => {
        try {
            await api.delete(`/servers/${id}`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            dispatch(fetchServers());
            toast.success("Server deleted successfully!"); // Success toast
        } catch (error) {
            console.error("Error deleting server:", error);
            toast.error("Failed to delete server!"); // Error toast
            throw error;
        }
    }
);

// Update a server
export const updateServer = createAsyncThunk(
    "servers/updateServer",
    async ({ id, updatedServer }, { dispatch }) => {
        try {
            const response = await api.put(`/servers/${id}`, updatedServer, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            dispatch(fetchServers()); // Fetch updated list of servers after update
            toast.success("Server updated successfully!"); // Success toast
            return response.data;
        } catch (error) {
            console.error("Error updating server:", error);
            toast.error("Failed to update server!"); // Error toast
            throw error;
        }
    }
);
// Monitor a server by ID
export const monitorServer = createAsyncThunk(
    "servers/monitorServer",
    async (id, { dispatch }) => {
        try {
            const response = await api.get(`/servers/${id}/monitor`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });


            return response.data.server;
        } catch (error) {
            console.error("Error monitoring server:", error);

            throw error;
        }
    }
);

const serversSlice = createSlice({
    name: "servers",
    initialState: {
        servers: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchServers.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchServers.fulfilled, (state, action) => {
                state.servers = action.payload;
                state.loading = false;
            })
            .addCase(fetchServers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(createServer.fulfilled, (state, action) => {
                state.servers.push(action.payload);
            })
            .addCase(deleteServer.fulfilled, (state, action) => {
                state.servers = state.servers.filter(
                    (server) => server._id !== action.meta.arg
                );
            })
            .addCase(updateServer.fulfilled, (state, action) => {
                const index = state.servers.findIndex(
                    (server) => server._id === action.payload._id
                );
                if (index !== -1) {
                    state.servers[index] = action.payload;
                }
            })
            .addCase(monitorServer.fulfilled, (state, action) => {
                const index = state.servers.findIndex(
                    (server) => server._id === action.payload._id
                );
                if (index !== -1) {
                    state.servers[index] = action.payload;
                }
            });
    },
});

export const selectServers = (state) => state.servers.servers;
export const selectLoading = (state) => state.servers.loading;
export const selectError = (state) => state.servers.error;

export default serversSlice.reducer;

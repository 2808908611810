import React, { useState, useEffect } from "react";
import "./Settings.css";
import { Link } from "react-router-dom";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Settings() {
  const [font, setFont] = useState(
    localStorage.getItem("font") || "montserrat"
  );

  // Apply font globally by setting a class on the root element
  useEffect(() => {
    document
      .getElementById("root")
      .classList.remove(
        "roboto",
        "montserrat",
        "open-sans",
        "lato",
        "raleway",
        "nunito"
      );
    document.getElementById("root").classList.add(font);
    localStorage.setItem("font", font); // Save the choice to localStorage
  }, [font]);

  return (
    <div id="settings">
      <div className="d-flex">
        <h1 className="text-primary text-xl">Settings</h1>
        
      </div>
      <p style={{ marginTop: 10 }}>
        Customize Ted Manager by selecting your preferred font style
        and theme appearance.
      </p>

      <section>
        <h3>Font</h3>
        <p>
          Select your preferred font style for the text displayed in Ted
          Manager.
        </p>
        <div>
          <button onClick={() => setFont("roboto")}>Roboto</button>
          <button onClick={() => setFont("montserrat")}>Montserrat</button>
          <button onClick={() => setFont("open-sans")}>Open Sans</button>
          <button onClick={() => setFont("lato")}>Lato</button>
          <button onClick={() => setFont("raleway")}>Raleway</button>
          <button onClick={() => setFont("nunito")}>Nunito</button>
        </div>
      </section>

      <section>
        <h3>Theme</h3>
        <p>Choose between light or dark theme for Ted Manager appearance.</p>
        <div>
          <button className="light">Light</button>
          <button className="dark">Dark</button>
        </div>
      </section>
    </div>
  );
}

export default Settings;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../utils/api"; // Assuming the api utility handles requests
import { toast } from "react-toastify"; // Toast notifications

// Monitor all servers
export const monitorAllServers = createAsyncThunk(
    "monitor/fetchAll",
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.get("/servers/monitor-all", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            return response.data; // { maintenance, down, active }
        } catch (error) {
            console.error("Error monitoring servers:", error);
            toast.error("Failed to monitor servers!");
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

const monitorSlice = createSlice({
    name: "monitor",
    initialState: {
        maintenance: [],
        down: [],
        active: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(monitorAllServers.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(monitorAllServers.fulfilled, (state, action) => {
                state.maintenance = action.payload.maintenance;
                state.down = action.payload.down;
                state.active = action.payload.active;
                state.loading = false;
            })
            .addCase(monitorAllServers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || "Failed to monitor servers";
            });
    },
});

// Selectors to get data from the store
export const selectMaintenance = (state) => state.monitor.maintenance;
export const selectDown = (state) => state.monitor.down;
export const selectActive = (state) => state.monitor.active;
export const selectMonitorLoading = (state) => state.monitor.loading;
export const selectMonitorError = (state) => state.monitor.error;

export default monitorSlice.reducer;

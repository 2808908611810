import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../utils/api";
import { logout } from "../Authentication/AthenticationSlice";

export const fetchSupportPlans = createAsyncThunk(
  "supportPlans/fetchSupportPlans",
  async (_, { dispatch }) => {
    try {
      const response = await api.get("/support-plans/", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (response.data.status === "unauthenticated") {
        dispatch(logout());
      }
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching support plans:", error);
      throw error;
    }
  }
);

export const deleteSupportPlan = createAsyncThunk(
  "supportPlans/deleteSupportPlan",
  async (id, { dispatch }) => {
    try {
      await api.delete(`/support-plans/${id}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      // Fetch updated plans after deletion
      dispatch(fetchSupportPlans());
    } catch (error) {
      console.error("Error deleting support plan:", error);
      throw error;
    }
  }
);

export const createSupportPlan = createAsyncThunk(
  "supportPlans/createSupportPlan",
  async (newPlan, { dispatch }) => {
    try {
      const response = await api.post("/support-plans", newPlan, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      console.log(response);
      // Fetch updated plans after creation
      dispatch(fetchSupportPlans());

      return response.data;
    } catch (error) {
      console.error("Error creating support plan:", error);
      throw error;
    }
  }
);

export const updateSupportPlan = createAsyncThunk(
  "supportPlans/updateSupportPlan",
  async ({ id, updatedPlan }, { dispatch }) => {
    try {
      const response = await api.put(`/support-plans/${id}`, updatedPlan, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      // Fetch updated plans after update
      dispatch(fetchSupportPlans());

      return response.data;
    } catch (error) {
      console.error("Error updating support plan:", error);
      throw error;
    }
  }
);

const supportPlansSlice = createSlice({
  name: "supportPlans",
  initialState: {
    plans: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSupportPlans.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSupportPlans.fulfilled, (state, action) => {
        state.plans = action.payload;
        state.loading = false;
      })
      .addCase(fetchSupportPlans.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteSupportPlan.fulfilled, (state, action) => {})
      .addCase(updateSupportPlan.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateSupportPlan.fulfilled, (state, action) => {
        state.loading = false;
      })
      // .addCase(updateSupportPlan.fulfilled, (state, action) => {
      //   const index = state.plans.findIndex(
      //     (plan) => plan._id === action.payload._id
      //   );
      //   if (index !== -1) {
      //     state.plans[index] = action.payload;
      //   }
      // })
      .addCase(updateSupportPlan.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const selectSupportPlans = (state) => state.supportPlans.plans;
export const selectLoading = (state) => state.supportPlans.loading;
export const selectError = (state) => state.supportPlans.error;

export default supportPlansSlice.reducer;

// import "./Users.css";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  reset,
  fetchUsers,
  createUser,
  fetchUserById,
  updateUser,
  deleteUser,
  blockUser,
} from "./UsersSlice";
import { fetchRoles } from "../roles/RolesSlice";
import "./Users.css";

import { SelectStyles } from "../../layouts/select/SelectStyles";
import Select from "react-select";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faTrash,
  faPen,
  faBan,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FaUserGear } from "react-icons/fa6";
import Modal from "../../layouts/modal/Modal";

function Users() {
  const dispatch = useDispatch();
  const {
    users,
    user,
    isLoading,
    isSuccess,
    isError,
    message,
    formErrors,
    isFormLoading,
  } = useSelector((store) => store.users);

  const { roles } = useSelector((store) => store.roles);

  const [UserFormOpen, setUserFormOpen] = useState(false);
  const [UserForm, setUserForm] = useState({
    name: "",
    email: "",
    roles: [],
    password: "",
    password_confirm: "",
  });
  const [UserToEdit, setUserToEdit] = useState();
  const [UserToDelete, setUserToDelete] = useState();
  const [SearchFilter, setSearchFilter] = useState("");

  useEffect(() => {
    if (!users) {
      dispatch(fetchUsers());
    }
    return () => {};
  }, [users]);

  useEffect(() => {
    if (!users) {
      dispatch(fetchRoles());
    }
    return () => {};
  }, [users]);

  useEffect(() => {
    if (isSuccess || isError) {
      setUserFormOpen(false);
      setUserToDelete(null);
      resetUserForm();
    }
    return () => {};
  }, [isSuccess, isError]);

  useEffect(() => {
    if (UserToEdit && !user) {
      dispatch(fetchUserById(UserToEdit));
    } else if (UserToEdit && user) {
      setUserForm({
        name: user.name,
        email: user.email,
        roles: user.roles.map((role) => role._id),
      });
      setUserFormOpen(true);
    }
    return () => {};
  }, [user, UserToEdit]);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, []);

  const onNewUserClick = () => {
    dispatch(reset());
    setUserFormOpen(true);
  };

  const onCloseModalClick = () => {
    setUserToEdit(null);
    dispatch(reset());
    setUserFormOpen(false);
    resetUserForm();
  };

  const onMainInputChange = (field, value) => {
    setUserForm((prev) => {
      return { ...prev, [field]: value };
    });
  };

  const resetUserForm = () => {
    setUserForm({
      name: "",
      email: "",
      roles: [],
      password: "",
      password_confirm: "",
    });
  };

  return (
    <>
      <div id="users">
        <div className="betterFlex" style={{ marginTop: -1 }}>
          <Link className="no-decoration" to="/ted-users">
            <h3 className="text-primary text-xl">Ted Users</h3>
          </Link>
          <div
            className={`info-message ${isSuccess ? "appear success" : ""} ${
              isError ? "appear danger" : ""
            }`}
          >
            {message}
          </div>
          <div className="d-flex gap-2">
            <Link
              to="/ted-roles"
              style={{ color: "white", textDecoration: "none" }}
            >
              <button
                className="btn btn-primary"
                style={{ fontSize: 10, padding: "10px 20px", borderRadius: 20 }}
              >
                <h3
                  style={{
                    fontSize: 16,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <FaUserGear
                    style={{
                      paddingRight: "5px",
                      fontSize: 20,
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  />
                  Roles
                </h3>
              </button>
            </Link>
            <button
              className="btn btn-success"
              style={{ fontSize: 10, padding: "10px 20px", borderRadius: 20 }}
              onClick={onNewUserClick}
            >
              <h3 style={{ fontSize: 16, color: "white" }}>
                <FontAwesomeIcon
                  icon={faPlus}
                  style={{ paddingRight: "5px" }}
                />
                New User
              </h3>
            </button>
          </div>
        </div>

        <div className="d-flex gap-2 w-100 align-items-center m-6">
          <input
            data-type="text"
            placeholder="Search"
            value={SearchFilter}
            onChange={(event) => {
              setSearchFilter(event.target.value);
            }}
            className="bg-primary search"
          />
          <button
            className="btn btn-lg btn-primary"
            onClick={() => {
              setSearchFilter("");
            }}
            style={{ borderRadius: 20, padding: "10px 20px" }}
          >
            <h4 style={{ paddingRight: "5px", color: "white" }}>
              <FontAwesomeIcon icon={faXmark} />
            </h4>
            Clear
          </button>
        </div>

        <div className="table-container2">
          {isLoading && <span className="loading-text2">Loading...</span>}
          {!isLoading && users?.length > 0 && (
            <div className="users-table2">
              <div className="table-header2">
                <div className="table-cell2 small-width">Name</div>
                <div className="table-cell2">Email</div>
                <div className="table-cell2">Roles</div>
                <div className="table-cell2">Actions</div>
              </div>
              {users
                ?.filter((user) =>
                  user.name.toLowerCase().includes(SearchFilter)
                )
                .map((user) => (
                  <div
                    className="table-row2"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                    key={user._id}
                  >
                    <div className="table-cell2 small-width text-primary textt">
                      {user.name}
                    </div>
                    <div className="table-cell2 text-primary textt">
                      {user.email}
                    </div>
                    <div className="table-cell2 text-secondary textt">
                      {user.roles.map((permission, index) => (
                        <span
                          key={index}
                          style={{
                            padding: "10px 20px",
                            borderRadius: 20,
                            backgroundColor: "#1683FF",
                            color: "white",
                          }}
                        >
                          {permission.name}
                          {index < user.roles.length - 1 ? "," : ""}
                        </span>
                      ))}
                    </div>
                    <div
                      className="table-cell2 icon-btn"
                      style={{ display: "flex", gap: 10 }}
                    >
                      <button
                        title="Edit User"
                        className="btn btn-others icon-btn"
                        style={{ borderRadius: 50 }}
                        onClick={() => {
                          dispatch(reset());
                          setUserToEdit(user._id);
                        }}
                      >
                        <FontAwesomeIcon icon={faPen} />
                      </button>
                      <button
                        title={user.blocked ? "Unblock User" : "Block User"}
                        className={`btn icon-btn ${
                          user.blocked ? "btn-warning" : "btn-others"
                        }`}
                        style={{ borderRadius: 50 }}
                        onClick={() => {
                          dispatch(reset());
                          dispatch(blockUser(user._id));
                        }}
                      >
                        <FontAwesomeIcon icon={faBan} />
                      </button>
                      <button
                        title="Delete User"
                        onClick={() => {
                          dispatch(reset());
                          setUserToDelete(user._id);
                        }}
                        className="btn icon-btn btn-others"
                        style={{ borderRadius: 50 }}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          )}
          {!isLoading && users?.length === 0 && (
            <div className="text-center text-primary fw-semibold">
              <h3>No Data</h3>
            </div>
          )}
        </div>
      </div>

      {/* Add User modal */}
      {UserFormOpen && (
        <Modal classes="bg-secondary" onCloseModalClick={onCloseModalClick}>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              if (UserToEdit) {
                dispatch(
                  updateUser({
                    id: UserToEdit,
                    data: UserForm,
                  })
                );
              } else {
                dispatch(createUser(UserForm));
              }
            }}
          >
            <div style={{ width: "25vw" }} className="support-plan-modal">
              <h1 className="text-primary m-6">
                {UserToEdit ? "Edit" : "New"} User
              </h1>
              <div className="form-group m-6 m-6">
                <label>Name *</label>
                <input
                  data-type="text"
                  placeholder="Name"
                  value={UserForm["name"]}
                  onChange={(event) =>
                    onMainInputChange("name", event.target.value)
                  }
                />
                {formErrors?.name && (
                  <span className="text-danger input-error">
                    {formErrors?.name}
                  </span>
                )}
              </div>
              <div className="form-group m-6">
                <label>Email *</label>
                <input
                  data-type="text"
                  placeholder="Email *"
                  value={UserForm["email"]}
                  onChange={(event) =>
                    onMainInputChange("email", event.target.value)
                  }
                />
                {formErrors?.email && (
                  <span className="text-danger input-error">
                    {formErrors?.email}
                  </span>
                )}
              </div>
              <div className="form-group m-6">
                <label>Roles</label>
                <Select
                  value={roles
                    ?.filter((role) => UserForm["roles"].includes(role._id))
                    .map((role) => {
                      return { label: role.name, value: role._id };
                    })}
                  onChange={(options) => {
                    onMainInputChange(
                      "roles",
                      options.map((option) => option.value)
                    );
                  }}
                  options={roles?.map((role) => {
                    return { label: role.name, value: role._id };
                  })}
                  isMulti={true}
                  placeholder="Roles"
                  styles={SelectStyles}
                />
                {formErrors?.roles && (
                  <span className="text-danger input-error">
                    {formErrors?.roles}
                  </span>
                )}
              </div>
              <div className="form-group m-6">
                <label>Password</label>
                <input
                  data-type="text"
                  placeholder="Password"
                  value={UserForm["password"]}
                  onChange={(event) =>
                    onMainInputChange("password", event.target.value)
                  }
                />
                {formErrors?.password && (
                  <span className="text-danger input-error">
                    {formErrors?.password}
                  </span>
                )}
              </div>
              <div className="form-group m-6">
                <label>Password confirm</label>
                <input
                  data-type="text"
                  placeholder="Password confirm"
                  value={UserForm["password_confirm"]}
                  onChange={(event) =>
                    onMainInputChange("password_confirm", event.target.value)
                  }
                />
                {formErrors?.password_confirm && (
                  <span className="text-danger input-error">
                    {formErrors?.password_confirm}
                  </span>
                )}
              </div>
              <div className="d-flex m-4">
                <button
                  disabled={isFormLoading}
                  className="btn btn-lg btn-primary mx-auto w-100"
                  type="submit"
                >
                  <h4>{isFormLoading ? "Loading..." : "Save"}</h4>
                </button>
              </div>
            </div>
          </form>
        </Modal>
      )}

      {/* Delete User Modal */}
      {UserToDelete && (
        <Modal
          classes="bg-primary"
          onCloseModalClick={() => {
            setUserToDelete(null);
          }}
        >
          <div className="delete-user-modal text-center">
            <h3 className="text-primary">
              Are you sure you want to delete this user?
            </h3>
            <button
              onClick={() => {
                dispatch(deleteUser(UserToDelete));
              }}
              className="btn btn-lg btn-danger mx-auto"
            >
              <h3>Delete</h3>
            </button>
          </div>
        </Modal>
      )}
    </>
  );
}

export default Users;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../utils/api";
import { logout } from "../Authentication/AthenticationSlice";

export const fetchSupportAssignees = createAsyncThunk(
  "supportAssignees/fetchAll",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const res = await api.get("/support-assignees", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      // console.log("API Response for fetchSupportAssignees:", res.data); // Debugging log
      if (res.data.status === "unauthenticated") {
        dispatch(logout());
        return rejectWithValue("User is unauthenticated.");
      }
      return res.data;
    } catch (error) {
      console.error("Error in fetchSupportAssignees:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch support assignees."
      );
    }
  }
);

// Fetch support assignee by ID
export const fetchSupportAssigneeById = createAsyncThunk(
  "supportAssignees/fetch",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const res = await api.get(`/support-assignees/${id}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.status === "unauthenticated") {
        dispatch(logout());
        return rejectWithValue("User is unauthenticated.");
      }
      return res.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch support assignee."
      );
    }
  }
);

// Create a new support assignee
export const createSupportAssignee = createAsyncThunk(
  "supportAssignees/create",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const res = await api.post("/support-assignees", payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.status === "unauthenticated") {
        dispatch(logout());
        return rejectWithValue("User is unauthenticated.");
      }
      return res.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to create support assignee."
      );
    }
  }
);

// Update a support assignee
export const updateSupportAssignee = createAsyncThunk(
  "supportAssignees/update",
  async ({ id, data }, { dispatch, rejectWithValue }) => {
    try {
      const res = await api.put(`/support-assignees/${id}`, data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.status === "unauthenticated") {
        dispatch(logout());
        return rejectWithValue("User is unauthenticated.");
      }
      return res.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to update support assignee."
      );
    }
  }
);

// Delete a support assignee
export const deleteSupportAssignee = createAsyncThunk(
  "supportAssignees/delete",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const res = await api.delete(`/support-assignees/${id}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.status === "unauthenticated") {
        dispatch(logout());
        return rejectWithValue("User is unauthenticated.");
      }
      return res.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to delete support assignee."
      );
    }
  }
);

// Initial state
const initialState = {
  assignees: [],
  assignee: null,
  formErrors: null,
  message: null,
  isLoading: false,
  isFormLoading: false,
  isSuccess: false,
  isError: false,
};

// Slice
const SupportAssigneesSlice = createSlice({
  name: "supportAssignees",
  initialState,
  reducers: {
    reset: (state) => {
      state.assignee = null;
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = null;
      state.isFormLoading = false;
      state.formErrors = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSupportAssignees.pending, (state) => {
      state.isLoading = true;
    });
    builder
      .addCase(fetchSupportAssignees.fulfilled, (state, { payload }) => {
        // console.log("Payload received in fulfilled:", payload);
        state.isLoading = false;
        state.assignees = payload.data || []; // Make sure payload.data contains the expected structure
      })
      .addCase(fetchSupportAssignees.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isError = true;
        state.message = payload;
      });

    // Fetch support assignee by ID
    builder
      .addCase(fetchSupportAssigneeById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSupportAssigneeById.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.assignee = payload.data || null;
      })
      .addCase(fetchSupportAssigneeById.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isError = true;
        state.message = payload;
      });

    // Create support assignee
    builder
      .addCase(createSupportAssignee.pending, (state) => {
        state.isFormLoading = true;
      })
      .addCase(createSupportAssignee.fulfilled, (state, { payload }) => {
        state.isFormLoading = false;
        if (payload.status === "form-error") {
          state.formErrors = payload.message;
        } else if (payload.status === "success") {
          state.assignees.push(payload.data);
          state.message = payload.message;
          state.isSuccess = true;
        }
      })
      .addCase(createSupportAssignee.rejected, (state, { payload }) => {
        state.isFormLoading = false;
        state.isError = true;
        state.message = payload;
      });

    // Update support assignee
    builder
      .addCase(updateSupportAssignee.pending, (state) => {
        state.isFormLoading = true;
      })
      .addCase(updateSupportAssignee.fulfilled, (state, { payload }) => {
        state.isFormLoading = false;
        if (payload.status === "success") {
          const index = state.assignees.findIndex(
            (a) => a._id === payload.data._id
          );
          if (index !== -1) state.assignees[index] = payload.data;
          state.message = payload.message;
          state.isSuccess = true;
        }
      })
      .addCase(updateSupportAssignee.rejected, (state, { payload }) => {
        state.isFormLoading = false;
        state.isError = true;
        state.message = payload;
      });

    // Delete support assignee
    builder
      .addCase(deleteSupportAssignee.pending, (state) => {
        state.isFormLoading = true;
      })
      .addCase(deleteSupportAssignee.fulfilled, (state, { payload }) => {
        state.isFormLoading = false;
        if (payload.status === "success") {
          state.assignees = state.assignees.filter(
            (a) => a._id !== payload.data.id
          );
          state.message = payload.message;
          state.isSuccess = true;
        }
      })
      .addCase(deleteSupportAssignee.rejected, (state, { payload }) => {
        state.isFormLoading = false;
        state.isError = true;
        state.message = payload;
      });
  },
});

export const { reset } = SupportAssigneesSlice.actions;

export default SupportAssigneesSlice.reducer;

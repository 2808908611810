import "./Dashboard.css";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchMainNumbers } from "./DashboardSlice";
import FilterPopup from "../../components/FilterPopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faRefresh } from "@fortawesome/free-solid-svg-icons";
import Skeleton from "../../components/Skeleton";

function Dashboard() {
  const currentYear = new Date().getFullYear();
  const years = Array.from(
    { length: currentYear - 2000 + 1 },
    (_, index) => currentYear - index
  );
  const date_options = { day: "2-digit", month: "short", year: "numeric" };
  const [popupFilter, setPopupFilter] = useState(null);
  const [filterType, setFilterType] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const openFilterPopup = (type) => {
    setPopupFilter(type);
    setFilterType(type);
  };

  const closeFilterPopup = () => {
    setPopupFilter(null);
    setFilterType("");
  };

  const applyFilters = () => {
    closeFilterPopup();
  };

  const clearAllFilters = () => {
    setFilters({
      totalPaid: { from: null, to: null }, // Reset to null to fetch all data
      totalDue: { from: null, to: null },
      totalSupport: { year: currentYear },
    });
  };

  const dispatch = useDispatch();
  const { mainNumbers } = useSelector((store) => store.dashboard);
  const [filters, setFilters] = useState({
    totalPaid: { from: null, to: null }, // Reset to null to fetch all data
    totalDue: { from: null, to: null },
    totalSupport: { year: currentYear },
  });
  useEffect(() => {
    dispatch(fetchMainNumbers(filters));
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, [dispatch, filters]);

  const handleFilterChange = (type, field, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [type]: {
        ...prevFilters[type],
        [field]: value,
      },
    }));
  };

  return (
    <div className="dashboard">
      <header className="dashboard-header">
        <h3>Dashboard</h3>
        <button
          className="ml-auto btn btn-primary"
          onClick={clearAllFilters}
          style={{
            fontSize: 15,
            fontWeight: "bold",
            padding: "11px 20px",
            borderRadius: 20,
            opacity: 1,
          }}
        >
          <FontAwesomeIcon
            icon={faRefresh}
            style={{ fontSize: 16, paddingRight: 5 }}
          />
          Refresh
        </button>
      </header>

      {/* <h4 style={{ paddingTop: 3 }}>Projects</h4> */}
      {isLoading ? (
        <Skeleton count={5} />
      ) : (
        <section className="project-states-container">
          {["leads", "meetings", "proposals", "sales", "approved"].map(
            (projectType) => (
              <Link
                key={projectType}
                className="project-link"
                to={`/ted-projects/${projectType}`}
              >
                <div className="project-card">
                  <h2>
                    {mainNumbers?.project_counts?.[projectType]?.[0]?.count ||
                      0}
                  </h2>
                  <h4>
                    {projectType.charAt(0).toUpperCase() + projectType.slice(1)}
                  </h4>
                </div>
              </Link>
            )
          )}
        </section>
      )}

      {isLoading ? (
        <Skeleton count={4} />
      ) : (
        <section className="summary-container">
          <div className="summary-box">
            <Link to={"/ted-projects/rejected"} className="summary-link">
              <h2>{mainNumbers?.project_counts?.rejected?.[0]?.count || 0}</h2>
              <h4>Rejected</h4>
            </Link>
          </div>
          <div className="summary-box">
            <Link to={"/ted-projects/stopped"} className="summary-link">
              <h2>{mainNumbers?.project_counts?.stopped?.[0]?.count || 0}</h2>
              <h4>Stopped</h4>
            </Link>
          </div>
          <div className="summary-box">
            <Link to={"/ted-contacts"} className="summary-link">
              <h2>{mainNumbers?.contacts_count || 0}</h2>
              <h4>Contacts</h4>
            </Link>
          </div>
          <div className="summary-box">
            <Link to={"/ted-teasing-contacts"} className="summary-link">
              <h2>{mainNumbers?.teasing_contacts_count || 0}</h2>
              <h4>Teasing Contacts</h4>
            </Link>
          </div>
        </section>
      )}

      {isLoading ? (
        <Skeleton count={2} />
      ) : (
        <section className="revenue-container">
          <div className="revenue-box">
            <Link to={"/ted-projects/sales"} className="revenue-link">
              <h2>
                {mainNumbers?.sales_revenue?.[0]
                  ? Number(
                      mainNumbers.sales_revenue[0].count["$numberDecimal"]
                    ).toLocaleString("en-US")
                  : 0}{" "}
                $
              </h2>
              <h4>Sales Revenue</h4>
            </Link>
          </div>
          <div className="revenue-box">
            <Link to={"/ted-projects/approved"} className="revenue-link">
              <h2>
                {mainNumbers?.approved_revenue?.[0]
                  ? Number(
                      mainNumbers.approved_revenue[0].count["$numberDecimal"]
                    ).toLocaleString("en-US")
                  : 0}{" "}
                $
              </h2>
              <h4>Approved Revenue</h4>
            </Link>
          </div>
        </section>
      )}

      {isLoading ? (
        <Skeleton count={3} />
      ) : (
        <section className="revenue-container">
          <div className="revenue-box" style={{ position: "relative" }}>
            <button
              className="btn btn-primary"
              onClick={() => openFilterPopup("totalPaid")}
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
                borderRadius: "100%",
                aspectRatio: "1/1",
                padding: "10px",
              }}
            >
              <FontAwesomeIcon icon={faFilter} />
            </button>

            <Link to={"/ted-support-plans/paid"} className="revenue-link">
              <h2>
                {mainNumbers?.total_paid
                  ? Number(mainNumbers.total_paid).toLocaleString("en-US")
                  : 0}{" "}
                $
              </h2>
              <h4>Total Paid</h4>
              <p style={{ position: "relative", marginTop: "0.5rem" }}>
                {filters?.totalPaid?.from
                  ? new Date(filters.totalPaid.from)
                      .toLocaleDateString("en-GB", date_options)
                      .replace(/\s/g, " ")
                  : ""}
                {filters.totalPaid?.from && filters.totalPaid?.to && "  -  "}
                {filters?.totalPaid?.to
                  ? new Date(filters.totalPaid.to)
                      .toLocaleDateString("en-GB", date_options)
                      .replace(/\s/g, " ")
                  : ""}
              </p>
            </Link>
          </div>
          <div className="revenue-box" style={{ position: "relative" }}>
            <button
              className="btn btn-primary"
              onClick={() => openFilterPopup("totalDue")}
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
                borderRadius: "100%",
                aspectRatio: "1/1",
                padding: "10px",
              }}
            >
              <FontAwesomeIcon icon={faFilter} />
            </button>

            <Link to={"/ted-support-plans/due"} className="revenue-link">
              <div>
                <h2>
                  {mainNumbers?.due_amount
                    ? Number(mainNumbers.due_amount).toLocaleString("en-US")
                    : 0}{" "}
                  $
                </h2>
                <h4>Total Due</h4>
                <p style={{ position: "relative", marginTop: "0.5rem" }}>
                  {filters?.totalDue?.from
                    ? new Date(filters.totalDue.from)
                        .toLocaleDateString("en-GB", date_options)
                        .replace(/\s/g, " ")
                    : ""}
                  {filters.totalDue?.from && filters.totalDue?.to && "  -  "}
                  {filters?.totalDue?.to
                    ? new Date(filters.totalDue.to)
                        .toLocaleDateString("en-GB", date_options)
                        .replace(/\s/g, " ")
                    : ""}
                </p>
              </div>
            </Link>
          </div>

          <div className="revenue-box" style={{ position: "relative" }}>
            <Link to={"/ted-support-plans/new"} className="revenue-link">
              <h2>
                {mainNumbers?.total_new_payments
                  ? Number(mainNumbers.total_new_payments).toLocaleString(
                      "en-US"
                    )
                  : 0}{" "}
                $
              </h2>
              <h4>Total Coming Soon</h4>
            </Link>
            <button
              disabled
              className="btn btn-primary"
              onClick={() => openFilterPopup("totalPaid")}
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
                borderRadius: "100%",
                aspectRatio: "1/1",
                padding: "10px",
              }}
            >
              <FontAwesomeIcon icon={faFilter} />
            </button>
          </div>
        </section>
      )}

      {isLoading ? (
        <Skeleton count={2} />
      ) : (
        <section className="revenue-container">
          <div className="revenue-box" style={{ position: "relative" }}>
            <button
              className="btn btn-primary"
              onClick={() => openFilterPopup("totalSupport")}
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
                borderRadius: "100%",
                aspectRatio: "1/1",
                padding: "10px",
              }}
            >
              <FontAwesomeIcon icon={faFilter} />
            </button>

            <Link to={"/ted-support-plans"} className="revenue-link">
              <h2>
                {mainNumbers?.total_support
                  ? Number(mainNumbers.total_support).toLocaleString("en-US")
                  : 0}{" "}
                $
              </h2>
              <h4>Total Support (Yearly) </h4>
              <p style={{ position: "relative", marginTop: "0.5rem" }}>
                {filters?.totalSupport?.year}
              </p>
            </Link>
          </div>
          <div className="revenue-box" style={{ position: "relative" }}>
            <Link to={"/ted-support-plans"} className="revenue-link">
              <h2>
                {mainNumbers?.total_support_month
                  ? Number(mainNumbers.total_support_month).toLocaleString("en-US")
                  : 0} $
              </h2>
              <h4>Total Support (Monthly) </h4>
              <p style={{ position: "relative", marginTop: "0.5rem" }}>
                {filters?.totalSupport?.year}
              </p>
            </Link>
          </div>
        </section>
      )}

      <FilterPopup
        isOpen={popupFilter !== null}
        onClose={closeFilterPopup}
        onApplyFilters={applyFilters}
        filters={{ ...filters, years }}
        handleFilterChange={handleFilterChange}
        filterType={filterType}
      />
    </div>
  );
}

export default Dashboard;

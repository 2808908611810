import React from "react";
import PropTypes from "prop-types";

const Skeleton = ({ count }) => {
  return (
    <div className="skeleton-wrapper">
      {Array.from({ length: count }).map((_, index) => (
        <div key={index} className="skeleton-image"></div>
      ))}
    </div>
  );
};

Skeleton.propTypes = {
  count: PropTypes.number.isRequired,
};

export default Skeleton;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  monitorAllServers,
  selectMaintenance,
  selectDown,
  selectActive,
  selectMonitorLoading,
  selectMonitorError,
} from "./MonitorSlice";
import { Link } from "react-router-dom";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataGrid } from "@mui/x-data-grid";
import "./Monitor.css";
import Servers from "../monitor/Servers";

const Monitor = () => {
  const dispatch = useDispatch();
  const [expandedServer, setExpandedServer] = useState(null); // Track expanded server
  const [selectedServer, setSelectedServer] = useState(null); // Track selected server
  const maintenance = useSelector(selectMaintenance);
  const down = useSelector(selectDown);
  const active = useSelector(selectActive);
  const loading = useSelector(selectMonitorLoading);
  const error = useSelector(selectMonitorError);
  const [firstFetchLoading, setFirstFetchLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(monitorAllServers());
      setFirstFetchLoading(false);
    };
    fetchData();
    const intervalId = setInterval(() => {
      dispatch(monitorAllServers());
    }, 5000);
    return () => clearInterval(intervalId);
  }, [dispatch]);

  if (firstFetchLoading) return <div className="loader-container">
    <div className="loader"></div>
  </div>;
  if (error) return <p>Error: {error}</p>;

  const serverStates = [
    { state: "Active", servers: active, color: "var(--btn-success)" },
    { state: "Down", servers: down, color: "var(--btn-danger)" },
    { state: "Maintenance", servers: maintenance, color: "var(--btn-warning)" },
  ];

  const columns = [
    { field: "serverName", headerName: "Server Name", width: 150 },
    { field: "serverDescription", headerName: "Server Description", width: 350 },
    { field: "serverIP", headerName: "IP Address", width: 150 },
    { field: "status", headerName: "Status", width: 150 },
    { field: "size", headerName: "Size", width: 150 },

  ];

  return (
    <div className="container monitor-container">
      <div
        className="d-flex gap-2 align-items-center m-6"
        style={{ justifyContent: "space-between" }}
      >
        <h3 className="text-primary text-xl">Server Monitor</h3>
        <Link
          className="ml-auto btn btn-primary transitionUp"
          style={{
            fontSize: 15,
            fontWeight: "bold",
            padding: "11px 20px",
            borderRadius: 20,
            textDecoration: "none",
          }}
          onClick={() => window.location.reload()}
        >
          <FontAwesomeIcon icon={faRefresh} style={{ paddingRight: 5 }} />
          Refresh
        </Link>
      </div>

      <div style={{ position: "relative" }}>
        <div className="sections">
          <div className="monitor-states-container-grid-3">
            {serverStates.map((state, index) => (
              <Link
                key={index}
                className="monitor-link"
                onClick={() => {
                  setExpandedServer(expandedServer === state.state ? null : state.state);
                  setSelectedServer(state.state);
                }}

              >
                <div className="monitor-card-server" style={{
                  backgroundColor: selectedServer === state.state ? "#41AFE250" : "",
                  color: selectedServer === state.state ? "#1683FF" : "",
                }}>
                  <h3>{state.state} Servers</h3>
                  <p>{state.servers.length}</p>
                  <div className="pulse-container">
                    <div
                      className="pulse"
                      style={{
                        "--pulseColor": state.color,
                      }}
                    ></div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>

        {expandedServer && (
          <div style={{ marginTop: "20px" }}>
            <h4 className="text-center">
              {expandedServer} Server Details
            </h4>
            <DataGrid
              rows={serverStates
                .find((state) => state.state === expandedServer)
                .servers.map((server, idx) => ({
                  id: idx,
                  serverName: server.serverName,
                  serverDescription: server.serverDetails.description,
                  serverIP: server.serverIP,
                  status: server.serverDetails.status,
                  size: server.serverDetails.size,

                }))}
              columns={columns}
              pageSize={5}
              autoHeight
              sx={{
                borderRadius: 2,
                marginTop: 2,
                "& .odd-row": {
                  backgroundColor: "#f0f4ff",
                },
              }}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0
                  ? "odd-row"
                  : "even-row"
              }
            />
          </div>
        )}
      </div>
      <Servers />
    </div>
  );
};

export default Monitor;

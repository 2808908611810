import "./Roles.css";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  reset,
  fetchRoles,
  createRole,
  fetchRoleById,
  updateRole,
  deleteRole,
} from "./RolesSlice";
import {
  fetchPermissions,
  createPermission,
} from "../permissions/PermissionsSlice";

import { SelectStyles } from "../../layouts/select/SelectStyles";
import Select from "react-select";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faTrash,
  faPen,
  faXmark,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "../../layouts/modal/Modal";

function Roles() {
  const dispatch = useDispatch();
  const {
    roles,
    role,
    isLoading,
    isSuccess,
    isError,
    message,
    formErrors,
    isFormLoading,
  } = useSelector((store) => store.roles);

  const { permissions } = useSelector((store) => store.permissions);

  const [RoleFormOpen, setRoleFormOpen] = useState(false);
  const [RoleForm, setRoleForm] = useState({
    name: "",
    permissions: [],
  });
  const [RoleToEdit, setRoleToEdit] = useState();
  const [RoleToDelete, setRoleToDelete] = useState();
  const [SearchFilter, setSearchFilter] = useState("");

  const [isPermissionModalOpen, setPermissionModalOpen] = useState(false);

  const onNewPermissionClick = () => {
    setPermissionModalOpen(true);
  };

  const closePermissionModal = () => {
    setPermissionModalOpen(false);
  };

  useEffect(() => {
    if (!roles) {
      dispatch(fetchRoles());
    }
    return () => {};
  }, [roles]);

  useEffect(() => {
    if (!permissions) {
      dispatch(fetchPermissions());
    }
    return () => {};
  }, [permissions]);

  useEffect(() => {
    if (isSuccess || isError) {
      setRoleFormOpen(false);
      setRoleToDelete(null);
      resetRoleForm();
    }
    return () => {};
  }, [isSuccess, isError]);

  useEffect(() => {
    if (RoleToEdit && !role) {
      dispatch(fetchRoleById(RoleToEdit));
    } else if (RoleToEdit && role) {
      setRoleForm({
        name: role.name,
        permissions: role.permissions.map((permission) => permission._id),
      });
      setRoleFormOpen(true);
    }
    return () => {};
  }, [role, RoleToEdit]);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, []);

  const onNewRoleClick = () => {
    dispatch(reset());
    setRoleFormOpen(true);
  };

  const onCloseModalClick = () => {
    setRoleToEdit(null);
    dispatch(reset());
    setRoleFormOpen(false);
    resetRoleForm();
  };

  const onMainInputChange = (field, value) => {
    setRoleForm((prev) => {
      return { ...prev, [field]: value };
    });
  };

  const resetRoleForm = () => {
    setRoleForm({
      name: "",
      permissions: [],
    });
  };

  const handleAddPermission = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const newPermission = {
      name: formData.get("name"),
      slug: formData.get("slug"),
      description: formData.get("description"),
    };

    try {
      const resultAction = await dispatch(createPermission(newPermission));
      if (createPermission.fulfilled.match(resultAction)) {
        console.log("Permission added:", resultAction.payload);
        closePermissionModal();
      } else {
        console.error("Error adding permission:", resultAction.payload);
      }
    } catch (error) {
      console.error("Error adding permission:", error);
    }
  };

  return (
    <>
      <div id="roles">
        <div className="betterFlex" style={{ marginTop: -1 }}>
          <Link className="no-decoration" to="/ted-roles">
            <h3 className="text-primary text-xl">Ted Roles</h3>
          </Link>
          <div
            className={`info-message ${isSuccess ? "appear success" : ""} ${
              isError ? "appear danger" : ""
            }`}
          >
            {message}
          </div>
          <div className="d-flex gap-2">
            <button
              className="btn btn-primary"
              style={{ fontSize: 10, padding: "10px 20px", borderRadius: 20 }}
              onClick={onNewPermissionClick}
            >
              <h3 style={{ fontSize: 16, color: "white" }}>
                <FontAwesomeIcon
                  icon={faPlus}
                  style={{ paddingRight: "5px" }}
                />
                Add Permission
              </h3>
            </button>
            <Link
              to="/ted-users"
              style={{ color: "white", textDecoration: "none" }}
            >
              <button
                className="btn btn-primary"
                style={{ fontSize: 10, padding: "10px 20px", borderRadius: 20 }}
              >
                <h3 style={{ fontSize: 16 }}>
                  <FontAwesomeIcon
                    icon={faUser}
                    style={{ paddingRight: "5px" }}
                  />
                  Users
                </h3>
              </button>
            </Link>
            <button
              className="ml-auto btn btn-success"
              style={{ fontSize: 10, padding: "10px 20px", borderRadius: 20 }}
              onClick={onNewRoleClick}
            >
              <h3 style={{ fontSize: 16, color: "white" }}>
                <FontAwesomeIcon
                  icon={faPlus}
                  style={{ paddingRight: "5px" }}
                />
                New Role
              </h3>
            </button>
          </div>
        </div>

        <div className="d-flex gap-2 w-100 align-items-center m-6">
          <input
            data-type="text"
            placeholder="Search"
            value={SearchFilter}
            onChange={(event) => {
              setSearchFilter(event.target.value);
            }}
            className="bg-primary search"
          />
          <button
            className="btn btn-lg btn-primary"
            onClick={() => {
              setSearchFilter("");
            }}
            style={{ borderRadius: 20, padding: "10px 20px" }}
          >
            <h4 style={{ paddingRight: "5px", color: "white" }}>
              <FontAwesomeIcon icon={faXmark} />
            </h4>
            Clear
          </button>
        </div>

        <div className="table-container-roles">
          {isLoading && <span className="loading-text-roles">Loading...</span>}
          {!isLoading && roles?.length > 0 && (
            <div className="roles-table">
              <div className="table-header-roles">
                <div className="table-cell-roles small-width">Name</div>
                <div className="table-cell-roles">Permissions</div>
                <div className="table-cell-roles">Actions</div>
              </div>
              {roles
                ?.filter((role) =>
                  role.name.toLowerCase().includes(SearchFilter)
                )
                .map((role) => (
                  <div
                    className="table-row-roles"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                    key={role._id}
                  >
                    <div className="table-cell-roles small-width text-primary textt">
                      {role.name}
                    </div>
                    <div
                      className="table-cell-roles text-secondary textt"
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        paddingTop: 10,
                        marginRight: 10,
                      }}
                    >
                      {role.permissions.map((permission, index) => (
                        <span
                          key={index}
                          style={{
                            padding: "5px",
                            marginBottom: "5px 0px",
                          }}
                        >
                          {permission.name}
                          {index < role.permissions.length - 1 ? "," : ""}
                        </span>
                      ))}
                    </div>
                    <div
                      className="table-cell-roles icon-btn"
                      style={{ display: "flex", gap: 10 }}
                    >
                      <button
                        className="btn btn-others icon-btn"
                        style={{ borderRadius: 50 }}
                        onClick={() => {
                          dispatch(reset());
                          setRoleToEdit(role._id);
                        }}
                      >
                        <FontAwesomeIcon icon={faPen} />
                      </button>
                      <button
                        onClick={() => {
                          dispatch(reset());
                          setRoleToDelete(role._id);
                        }}
                        className="btn icon-btn btn-others"
                        style={{ borderRadius: 50 }}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          )}
          {!isLoading && roles?.length === 0 && (
            <div className="text-center text-primary fw-semibold">
              <h3>No Data</h3>
            </div>
          )}
        </div>
      </div>

      {/* Add Role modal */}
      {RoleFormOpen && (
        <Modal classes="bg-secondary" onCloseModalClick={onCloseModalClick}>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              if (RoleToEdit) {
                dispatch(
                  updateRole({
                    id: RoleToEdit,
                    data: RoleForm,
                  })
                );
              } else {
                dispatch(createRole(RoleForm));
              }
            }}
          >
            <div className="role-modal support-plan-modal">
              <h1 className="text-primary" style={{ marginBottom: 20 }}>
                {RoleToEdit ? "Edit" : "New"} Role
              </h1>
              <div className="form-group">
                <label>Name*</label>
                <input
                  data-type="text"
                  placeholder="Name"
                  value={RoleForm["name"]}
                  onChange={(event) =>
                    onMainInputChange("name", event.target.value)
                  }
                />
                {formErrors?.name && (
                  <span className="text-danger input-error">
                    {formErrors?.name}
                  </span>
                )}
              </div>
              <div className="form-group" style={{ marginTop: 10 }}>
                <label>Permissions</label>
                <Select
                  value={permissions
                    ?.filter((permission) =>
                      RoleForm["permissions"].includes(permission._id)
                    )
                    .map((permission) => {
                      return { label: permission.name, value: permission._id };
                    })}
                  onChange={(options) => {
                    onMainInputChange(
                      "permissions",
                      options.map((option) => option.value)
                    );
                  }}
                  options={permissions?.map((permission) => {
                    return { label: permission.name, value: permission._id };
                  })}
                  isMulti={true}
                  placeholder="Permissions"
                  styles={SelectStyles}
                  style={{ height: 200 }}
                />
                {formErrors?.permissions && (
                  <span className="text-danger input-error">
                    {formErrors?.permissions}
                  </span>
                )}
              </div>
              <div className="d-flex" style={{ marginTop: 10 }}>
                <button
                  disabled={isFormLoading}
                  className="btn btn-lg btn-primary mx-auto w-100"
                  type="submit"
                >
                  <h4>{isFormLoading ? "Loading..." : "Save"}</h4>
                </button>
              </div>
            </div>
          </form>
        </Modal>
      )}

      {/* Delete Role Modal */}
      {RoleToDelete && (
        <Modal
          classes="bg-primary"
          onCloseModalClick={() => {
            setRoleToDelete(null);
          }}
        >
          <div className="delete-role-modal text-center">
            <h3 className="text-primary">
              Are you sure you want to delete this role?
            </h3>
            <h4 className="text-warning" style={{ marginTop: "1rem" }}>
              Some users might lose access.
            </h4>
            <button
              onClick={() => {
                dispatch(deleteRole(RoleToDelete));
              }}
              className="btn btn-lg btn-danger mx-auto"
            >
              <h3>Delete</h3>
            </button>
          </div>
        </Modal>
      )}

      {/* Add Permission Modal */}
      {isPermissionModalOpen && (
        <Modal classes="bg-primary" onCloseModalClick={closePermissionModal}>
          <div className="modal-content">
            <h2 style={{ margin: "10spx 0" }}>Add New Permission</h2>
            <form onSubmit={handleAddPermission} style={{display: "flex", flexDirection: "column", gap: 10}}>
              <div className="form-group">
                <label htmlFor="permissionName">Permission Name</label>
                <input type="text" id="permissionName" name="name" required />
              </div>
              <div className="form-group">
                <label htmlFor="permissionSlug">Permission Slug</label>
                <input type="text" id="permissionSlug" name="slug" required />
              </div>
              <div className="form-group">
                <label htmlFor="permissionDescription">Description</label>
                <textarea
                  id="permissionDescription"
                  name="description"
                ></textarea>
              </div>
              <button type="submit" className="btn btn-primary w-100">
                Add Permission
              </button>
            </form>
          </div>
        </Modal>
      )}
    </>
  );
}

export default Roles;

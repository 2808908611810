export const SelectStyles = {
  container: (baseStyles, state) => ({
    ...baseStyles,
    width: "100%",
  }),
  dropdownIndicator: (baseStyles, state) => ({
    display: "none !important",
  }),
  indicatorSeparator: (baseStyles, state) => ({
    display: "none !important",
  }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: "#F9FAFB",
    marginTop: "5px",
    width: "100% !important",
    padding: "0px !important",
    border: "1px solid #E3E3E6 !important",
    borderRadius: "8px !important",
    transition: "border-color 0.2s !important",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.05) !important",
  }),
  input: (baseStyles, state) => ({
    ...baseStyles,
    boxShadow: "none !important",
    color: "var(--text-primary)",
    padding: "0px !important",
    margin: "0px",
    fontSize: "14px",
    width: 0,
  }),
  placeholder: (baseStyles, state) => ({
    ...baseStyles,
    color: "var(--input-placeholder)",
    fontWeight: "500",
    fontSize: "14px",
    padding: "0px !important",
  }),

  menu: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: "var(--bg-primary)",
    boxShadow: "0px 0px 10px 0 var(--input-shadow)",
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    color: state.isFocused
      ? "var(--select-option-hover-color)"
      : "var(--text-primary)",
    fontSize: "14px",
    fontWeight: "500",
    padding: "0.5rem 1rem",
    backgroundColor: state.isFocused ? "var(--text-highlight)" : "#F9FAFB",
  }),

  valueContainer: (baseStyles, state) => ({
    ...baseStyles,
    padding: "0px 13px",
    margin: 0,
  }),
  multiValue: (baseStyles, state) => ({
    ...baseStyles,
    margin: "7px 10px 5px -5px",
    backgroundColor: "var(--btn-primary)",
    border: "none !important",
    borderRadius: "3px",
  }),
  multiValueLabel: (baseStyles, state) => ({
    ...baseStyles,
    color: "var(--btn-color)",
    fontSize: "14px",
    fontWeight: "500",
  }),
  multiValueRemove: (baseStyles, state) => ({
    ...baseStyles,
    color: "var(--btn-color) !important",
    background: state.isHover
      ? "var(--btn-danger) !important"
      : "var(--btn-primary)",
  }),
  singleValue: (baseStyles, state) => ({
    ...baseStyles,
    color: "var(--text-primary)",
    fontSize: "14px",
    fontWeight: "500",
  }),

  clearIndicator: (baseStyles, state) => ({
    ...baseStyles,
    display: "none",
  }),
  noOptionsMessage: (baseStyles, state) => ({
    ...baseStyles,
    color: "var(--text-secondary)",
    fontSize: "14px",
  }),
};

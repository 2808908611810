import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  reset,
  fetchSupportAssignees,
  createSupportAssignee,
  fetchSupportAssigneeById,
  updateSupportAssignee,
  deleteSupportAssignee,
} from "./SupportAssigneesSlice";
import Modal from "../../layouts/modal/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faArrowLeft,
  faPen,
  faTrash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function Assignees() {
  const dispatch = useDispatch();
  const { assignees, isLoading, isSuccess, isError, message } = useSelector(
    (store) => store.supportAssignees || {}
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newAssignee, setNewAssignee] = useState({ name: "", email: "" });
  const [searchFilter, setSearchFilter] = useState("");
  const [assigneeToEdit, setAssigneeToEdit] = useState(null);
  const [assigneeToDelete, setAssigneeToDelete] = useState(null);

  // Fetch assignees on component mount
  useEffect(() => {
    if (!assignees || assignees.length === 0) {
      dispatch(fetchSupportAssignees());
    }
    return () => {};
  }, [dispatch, assignees]);

  useEffect(() => {
    if (isSuccess || isError) {
      setIsModalOpen(false);
      resetAssigneeForm();
    }
    return () => {};
  }, [isSuccess, isError]);

  const resetAssigneeForm = () => {
    setNewAssignee({ name: "", email: "" });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!newAssignee.name || !newAssignee.email) {
      alert("Name and Email are required.");
      return;
    }

    if (assigneeToEdit) {
      // When editing, make sure the assignee's id is passed correctly in the correct format
      dispatch(updateSupportAssignee({ id: assigneeToEdit, data: newAssignee }))
        .unwrap()
        .then(() => {
          resetAssigneeForm();
          setAssigneeToEdit(null);
          setIsModalOpen(false);
        })
        .catch((error) => {
          console.error("Error updating assignee:", error);
        });
    } else {
      // When adding a new assignee
      dispatch(createSupportAssignee(newAssignee))
        .unwrap()
        .then(() => {
          resetAssigneeForm();
          setIsModalOpen(false);
        })
        .catch((error) => {
          console.error("Error creating assignee:", error);
        });
    }
  };



  const handleEditAssignee = (assigneeId) => {
    const assignee = assignees.find((item) => item._id === assigneeId);
    if (assignee) {
      setAssigneeToEdit(assigneeId);
      setNewAssignee({ name: assignee.name, email: assignee.email });
      setIsModalOpen(true);
    }
  };

  const handleDeleteAssignee = (assigneeId) => {
    dispatch(deleteSupportAssignee(assigneeId))
      .unwrap()
      .then(() => {
        setAssigneeToDelete(null);
      })
      .catch((error) => {
        console.error("Error deleting assignee:", error);
      });
  };

  return (
    <div id="assignees">
      <div
        className="d-flex gap-2 align-items-center m-6"
        style={{ justifyContent: "space-between" }}
      >
        <h3 className="text-primary text-xl">Ted Support Assignees</h3>
        <div className="d-flex align-items-center gap-1">
          <Link
            style={{ textDecoration: "none" }}
            className="ml-auto btn btn-primary pproject transitionUp"
            to="/ted-support-plans"
          >
            <FontAwesomeIcon
              icon={faArrowLeft}
              style={{ paddingRight: "5px" }}
            />
            Back to Support
          </Link>
          <button
            className="ml-auto btn btn-success transitionUp"
            onClick={() => setIsModalOpen(true)}
            style={{
              fontSize: 15,
              fontWeight: "bold",
              padding: "11px 20px",
              borderRadius: 20,
            }}
          >
            <FontAwesomeIcon icon={faPlus} style={{ paddingRight: "5px" }} />
            New PM
          </button>
        </div>
      </div>

      <div className="d-flex gap-2 w-100 align-items-center m-6">
        <input
          data-type="text"
          placeholder="Search"
          value={searchFilter}
          onChange={(event) => {
            setSearchFilter(event.target.value);
          }}
          className="bg-primary search"
        />
        <button
          className="btn btn-lg btn-primary"
          onClick={() => {
            setSearchFilter("");
          }}
          style={{ borderRadius: 20, padding: "10px 20px" }}
        >
          <h4 style={{ paddingRight: "5px", color: "white" }}>
            <FontAwesomeIcon icon={faXmark} />
          </h4>
          Clear
        </button>
      </div>

      <div className="table-container-roles">
        <div className="roles-table">
          <div className="table-header-roles">
            <div className="table-cell-roles">Name</div>
            <div className="table-cell-roles">Email</div>
            <div className="table-cell-roles">Actions</div>
          </div>
          {isLoading ? (
            <div className="text-center text-primary fw-semibold">
              <h3>Loading...</h3>
            </div>
          ) : Array.isArray(assignees) && assignees.length > 0 ? (
            assignees
              .filter((assignee) =>
                assignee.name.toLowerCase().includes(searchFilter)
              )
              .map((assignee) => (
                <div
                  className="table-row-roles"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                  key={assignee._id}
                >
                  <div className="table-cell-roles text-primary textt">
                    {assignee.name}
                  </div>

                  <div className="table-cell-roles text-primary textt">
                    {assignee.email}
                  </div>

                  <div
                    className="table-cell-roles icon-btn"
                    style={{ display: "flex", gap: 10 }}
                  >
                    <button
                      className="btn btn-others icon-btn"
                      style={{ borderRadius: 50 }}
                      onClick={() => handleEditAssignee(assignee._id)}
                    >
                      <FontAwesomeIcon icon={faPen} />
                    </button>
                    <button
                      onClick={() => handleDeleteAssignee(assignee._id)}
                      className="btn icon-btn btn-others"
                      style={{ borderRadius: 50 }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </div>
                </div>
              ))
          ) : (
            <div className="text-center text-primary fw-semibold">
              <h3>No Data</h3>
            </div>
          )}
        </div>
      </div>

      {message && (
        <div className={`alert ${isError ? "alert-danger" : "alert-success"}`}>
          {message}
        </div>
      )}

      {/* Add or Edit Assignee Modal */}
      {isModalOpen && (
        <Modal onCloseModalClick={() => setIsModalOpen(false)}>
          <div className="modal-content">
            <h2 style={{ margin: "10px 0" }}>
              {assigneeToEdit ? "Edit PM" : "Add New PM"}
            </h2>
            <form
              onSubmit={handleSubmit}
              style={{ display: "flex", flexDirection: "column", gap: 10 }}
            >
              <div className="form-group">
                <label>Name</label>
                <input
                  type="text"
                  placeholder="Enter PM name"
                  value={newAssignee.name}
                  onChange={(e) =>
                    setNewAssignee({ ...newAssignee, name: e.target.value })
                  }
                />
              </div>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  placeholder="Enter PM email"
                  value={newAssignee.email}
                  onChange={(e) =>
                    setNewAssignee({ ...newAssignee, email: e.target.value })
                  }
                />
              </div>
              <button
                type="submit"
                className="btn btn-primary w-100"
                style={{ marginTop: 10 }}
              >
                {assigneeToEdit ? "Update" : "Submit"}
              </button>
            </form>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default Assignees;

import "./Contacts.css";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CSVLink } from "react-csv";
import api from "../../utils/api";

import {
  reset,
  createContact,
  fetchContactById,
  fetchContacts,
  updateContact,
  deleteContact,
  highlighContact,
  importContacts,
} from "./ContactsSlice";
import { fetchContactTypes } from "./ContactTypesSlice";
import { fetchContactFields } from "./ContactFieldsSlice";

import { SelectStyles } from "../../layouts/select/SelectStyles";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faPen,
  faTrash,
  faStar,
  faClone,
  faXmark,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "../../layouts/modal/Modal";

import Select from "react-select";

import {
  faFileImport,
  faFileExport,
  faFileExcel,
} from "@fortawesome/free-solid-svg-icons";

function Contacts() {
  const dispatch = useDispatch();
  const {
    contacts,
    contact,
    isLoading,
    isSuccess,
    isError,
    message,
    formErrors,
    isFormLoading,
  } = useSelector((store) => store.contacts);
  const { contactTypes } = useSelector((store) => store.contactTypes);
  const { contactFields } = useSelector((store) => store.contactFields);

  const [ContactToEdit, setContactToEdit] = useState();
  const [ContactToDuplicate, setContactToDuplicate] = useState();
  const [ContactToDelete, setContactToDelete] = useState();

  const [FileToImport, setFileToImport] = useState();

  const [ContactFormOpen, setContactFormOpen] = useState(false);
  const [ContactForm, setContactForm] = useState({
    first_name: "",
    last_name: "",
    mobile_number: "",
    email: "",
    type: "",
    note: "",
  });

  const [Filters, setFilters] = useState({
    search_fields: [],
    search: "",
    search_company: "",
    type_filter: [],
    sort: null,
    highlighted_only: false,
  });

  useEffect(() => {
    if (!contactTypes) {
      dispatch(fetchContactTypes());
    }
    return () => {};
  }, [contactTypes]);

  useEffect(() => {
    if (!contactFields) {
      dispatch(fetchContactFields());
    } else {
      let fieldsObject = {};
      contactFields.forEach((field) => {
        fieldsObject[field.key] = "";
      });
      setContactForm((prev) => {
        return {
          ...prev,
          ...fieldsObject,
        };
      });
    }
    return () => {};
  }, [contactFields]);

  useEffect(() => {
    if (!contacts) {
      dispatch(fetchContacts());
    }
    return () => {};
  }, [contacts]);

  useEffect(() => {
    if (isSuccess || isError) {
      setContactFormOpen(false);
      setContactToEdit(null);
      setContactToDuplicate(null);
      setContactToDelete(null);
      document.getElementById("import-file-input").value = "";
      resetContactForm();
    }
    return () => {};
  }, [isSuccess, isError]);

  useEffect(() => {
    if (ContactToEdit && !contact) {
      dispatch(fetchContactById(ContactToEdit));
    } else if (ContactToDuplicate && !contact) {
      dispatch(fetchContactById(ContactToDuplicate));
    } else if ((ContactToEdit || ContactToDuplicate) && contact) {
      setContactForm({
        first_name: contact.first_name,
        last_name: contact.last_name,
        mobile_number: contact.mobile_number,
        email: contact.email,
        type: contact.type._id,
        note: contact.note,
      });

      let fieldsObject = {};
      contactFields.forEach((field) => {
        fieldsObject[field.key] = contact[field.key];
      });
      setContactForm((prev) => {
        return {
          ...prev,
          ...fieldsObject,
        };
      });

      setContactFormOpen(true);
    }
    return () => {};
  }, [contact, ContactToEdit, ContactToDuplicate]);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, []);

  const onNewContactClick = () => {
    dispatch(reset());
    setContactFormOpen(true);
  };

  const onCloseModalClick = () => {
    dispatch(reset());
    setContactFormOpen(false);
    setContactToEdit(null);
    setContactToDuplicate(null);
    resetContactForm();
  };

  const onMainInputChange = (field, value) => {
    setContactForm((prev) => {
      return { ...prev, [field]: value };
    });
  };

  const resetContactForm = () => {
    setContactForm({
      first_name: "",
      last_name: "",
      mobile_number: "",
      email: "",
      type: "",
      note: "",
    });
    let fieldsObject = {};
    contactFields.forEach((field) => {
      fieldsObject[field.key] = "";
    });
    setContactForm((prev) => {
      return {
        ...prev,
        ...fieldsObject,
      };
    });
  };

  const serialize = function (obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  };

  return (
    <>
      <div id="contacts">
        <div
          className="d-flex wrap gap-2 align-items-center justify-content-between"
          style={{ marginBottom: 20 }}
        >
          <Link className="no-decoration" to="/ted-contacts">
            <h3 className="text-primary text-xl">Ted Contacts</h3>
          </Link>
          <div
            className={`info-message ${isSuccess ? "appear success" : ""} ${
              isError ? "appear danger" : ""
            }`}
          >
            {message}
          </div>
          <input
            style={{
              position: "fixed",
              top: "-10000px",
              left: "-10000px",
              opacity: "0",
            }}
            type="file"
            id="import-file-input"
            onChange={(e) => {
              let data = new FormData();
              data.append("file", e.target.files[0]);
              dispatch(importContacts(data));
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 10,
            }}
          >
            <button
              onClick={() => {
                dispatch(reset());
                document.getElementById("import-file-input").click();
              }}
              className="ml-auto btn no-decoration btn-rounded btn-icons transitionUp"
              style={{ backgroundColor: "#562AD1" }}
            >
              <FontAwesomeIcon icon={faFileImport} style={{ fontSize: 16 }} />
              <h4>Import Data</h4>
            </button>
            <CSVLink
              className="btn no-decoration btn-rounded btn-icons transitionUp"
              style={{ backgroundColor: "#562AD1" }}
              data={
                contacts
                  ? contacts
                      .filter((contact) => {
                        let show = true;
                        if (Filters["search"]) {
                          show = false;
                          if (Filters["search_fields"].length > 0) {
                            if (
                              Filters["search_fields"].includes("first_name") &&
                              contact.first_name
                                .toLowerCase()
                                .includes(Filters["search"])
                            ) {
                              show = true;
                            }
                            if (
                              Filters["search_fields"].includes("last_name") &&
                              contact.last_name
                                .toLowerCase()
                                .includes(Filters["search"])
                            ) {
                              show = true;
                            }
                            if (
                              Filters["search_fields"].includes("email") &&
                              contact.email
                                .toLowerCase()
                                .includes(Filters["search"])
                            ) {
                              show = true;
                            }
                            if (
                              Filters["search_fields"].includes("note") &&
                              contact.note
                                .toLowerCase()
                                .includes(Filters["search"])
                            ) {
                              show = true;
                            }
                            for (let i = 0; i < contactFields.length; i++) {
                              if (
                                Filters["search_fields"].includes(
                                  contactFields[i].key
                                ) &&
                                contact[contactFields[i].key]
                                  .toLowerCase()
                                  .includes(Filters["search"])
                              ) {
                                show = true;
                              }
                            }
                          } else {
                            if (
                              contact.first_name
                                .toLowerCase()
                                .includes(Filters["search"])
                            ) {
                              show = true;
                            }
                            if (
                              contact.last_name
                                .toLowerCase()
                                .includes(Filters["search"])
                            ) {
                              show = true;
                            }
                            if (
                              contact.email
                                .toLowerCase()
                                .includes(Filters["search"])
                            ) {
                              show = true;
                            }
                            if (
                              contact.note
                                .toLowerCase()
                                .includes(Filters["search"])
                            ) {
                              show = true;
                            }
                            for (let i = 0; i < contactFields.length; i++) {
                              if (
                                contact[contactFields[i].key]
                                  .toLowerCase()
                                  .includes(Filters["search"])
                              ) {
                                show = true;
                              }
                            }
                          }
                        }
                        if (Filters["search_company"]) {
                        }
                        if (Filters["type_filter"].length > 0) {
                          if (
                            !Filters["type_filter"].includes(contact.type._id)
                          ) {
                            show = false;
                          }
                        }
                        if (Filters["highlighted_only"]) {
                          if (!contact.is_highlighted) {
                            show = false;
                          }
                        }
                        return show;
                      })
                      .map((contact) => {
                        return {
                          ...contact,
                          type: contact.type.name,
                        };
                      })
                  : []
              }
            >
              <FontAwesomeIcon icon={faFileExport} style={{ fontSize: 15 }} />
              <h5>Export Data</h5>
            </CSVLink>
            <CSVLink
              className="btn no-decoration btn-rounded btn-icons transitionUp"
              style={{ backgroundColor: "#562AD1" }}
              data={
                contacts
                  ? contacts.map((contact) => {
                      return {
                        ...contact,
                        type: contact.type.name,
                      };
                    })
                  : []
              }
            >
              <FontAwesomeIcon icon={faFileExcel} style={{ fontSize: 16 }} />
              <h5>Export All Data</h5>
            </CSVLink>
          </div>
          <div className="d-flex gap-1">
            <button
              className="ml-auto btn btn-success transitionUp"
              style={{
                fontSize: 15,
                fontWeight: "bold",
                padding: "11px 20px",
                borderRadius: 20,
              }}
              onClick={onNewContactClick}
            >
              <FontAwesomeIcon icon={faPlus} style={{ paddingRight: "5px" }} />
              New Contact
            </button>
          </div>
        </div>

        {/* Filters */}
        <div
          className="bg-primary card table-container2"
          style={{ overflow: "visible" }}
        >
          <div className="d-flex wrap gap-2 w-100 align-items-center m-6">
            <div className="form-group flex-grow-1">
              <label>Search</label>
              <input
                className="new-input"
                data-type="text"
                placeholder="Search"
                value={Filters["search"]}
                onChange={(event) => {
                  setFilters((prev) => {
                    return { ...prev, search: event.target.value };
                  });
                }}
              />
            </div>
            <div className="form-group flex-grow-1 custom-group">
              <label>Select Fields to Search</label>
              <Select
                styles={SelectStyles}
                placeholder="Select Fields to Search"
                value={Filters["search_fields"].map((field) => {
                  return [
                    { label: "First Name", value: "first_name" },
                    { label: "Last Name", value: "last_name" },
                    { label: "Email", value: "email" },
                    { label: "Note", value: "note" },
                  ]
                    .concat(
                      contactFields?.map((field) => {
                        return { label: field.name, value: field.key };
                      })
                    )
                    .find((option) => option.value == field);
                })}
                onChange={(value) => {
                  setFilters((prev) => {
                    return {
                      ...prev,
                      search_fields: value.map((field) => field.value),
                    };
                  });
                }}
                options={[
                  { label: "First Name", value: "first_name" },
                  { label: "Last Name", value: "last_name" },
                  { label: "Email", value: "email" },
                  { label: "Note", value: "note" },
                ].concat(
                  contactFields?.map((field) => {
                    return { label: field.name, value: field.key };
                  })
                )}
                isMulti={true}
              />
            </div>
            <div className="form-group flex-grow-1">
              <label>Search Company</label>
              <input
                data-type="text"
                className="new-input"
                placeholder="Search Company"
                value={Filters["search_company"]}
                onChange={(event) => {
                  setFilters((prev) => {
                    return { ...prev, search_company: event.target.value };
                  });
                }}
              />
            </div>
          </div>
          <div className="d-flex wrap gap-2 w-100 align-items-end">
            <div className="flex-grow-1 form-group">
              <label>Filter by Type</label>
              <Select
                styles={SelectStyles}
                isMulti={true}
                options={contactTypes?.map((type) => {
                  return { value: type._id, label: type.name };
                })}
                value={Filters["type_filter"].map((type_id) => {
                  let type = contactTypes?.find((_type) => {
                    return _type._id == type_id;
                  });
                  return {
                    label: type.name,
                    value: type._id,
                  };
                })}
                onChange={(value) => {
                  setFilters((prev) => {
                    return {
                      ...prev,
                      type_filter: value.map((type) => type.value),
                    };
                  });
                }}
                placeholder="Filter by Type"
              />
            </div>
            <div className="flex-grow-1 form-group">
              <label>Sort by</label>
              <Select
                styles={SelectStyles}
                placeholder="Sort by"
                value={Filters["sort"]}
                onChange={(value) =>
                  setFilters((prev) => {
                    return {
                      ...prev,
                      sort: value,
                    };
                  })
                }
                options={[
                  {
                    label: "Sort by Name (Descending)",
                    value: "name-desc",
                  },
                  {
                    label: "Sort by Name (Ascending)",
                    value: "name-asc",
                  },
                  {
                    label: "Sort by Company (Descending)",
                    value: "company-desc",
                  },
                  {
                    label: "Sort by Company (Ascending)",
                    value: "company-asc",
                  },
                ]}
              />
            </div>
            <div className="d-flex wrap gap-2" style={{ marginBottom: 2 }}>
              <button
                className={`btn btn-primary btn-rounded ${
                  Filters.highlighted_only ? "active" : ""
                }`}
                onClick={() => {
                  setFilters((prev) => {
                    return {
                      ...prev,
                      highlighted_only: !prev.highlighted_only,
                    };
                  });
                }}
              >
                <h4 style={{ paddingRight: "5px" }}>
                  <FontAwesomeIcon icon={faStar} />
                </h4>
                Highlighted Only
              </button>
              <button
                className="btn btn-primary btn-rounded"
                onClick={() => {
                  setFilters((prev) => {
                    return {
                      search_fields: [],
                      search: "",
                      search_company: "",
                      type_filter: [],
                      sort: null,
                      highlighted_only: false,
                    };
                  });
                }}
              >
                <h4 style={{ paddingRight: "5px" }}>
                  <FontAwesomeIcon icon={faXmark} />
                </h4>
                Clear Filters
              </button>
            </div>
          </div>
        </div>

        {/* Contacts */}
        <div className="table-container">
          <div className="table-header d-flex gap-1">
            <div className="table-cell small-width">First Name</div>
            <div className="table-cell small-width">Last Name</div>
            <div className="table-cell">Email</div>
            <div className="table-cell">Company</div>
            <div className="table-cell">Contact Type</div>
            <div className="table-cell">Actions</div>
          </div>
          {isLoading && (
            <span
              className="loading-text"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 20,
              }}
            >
              Loading...
            </span>
          )}
          {!isLoading &&
            contacts?.length > 0 &&
            contacts
              .filter((contact) => {
                let show = true;
                if (Filters["search"]) {
                  show = false;
                  const searchLower = Filters["search"].toLowerCase();
                  // Search Logic
                  if (Filters["search_fields"].length > 0) {
                    // Check against search fields
                    show = Filters["search_fields"].some((field) => {
                      return contact[field]
                        ?.toLowerCase()
                        .includes(searchLower);
                    });
                  } else {
                    // Default search
                    show = ["first_name", "last_name", "email", "note"].some(
                      (field) =>
                        contact[field]?.toLowerCase().includes(searchLower)
                    );
                  }
                }
                // Apply other filters
                if (
                  Filters["type_filter"].length > 0 &&
                  !Filters["type_filter"].includes(contact.type._id)
                ) {
                  show = false;
                }
                if (Filters["highlighted_only"] && !contact.is_highlighted) {
                  show = false;
                }
                return show;
              })
              ?.map((contact) => (
                <div
                  className="table-row d-flex align-items-center gap-1"
                  key={contact._id}
                >
                  <div className="table-cell fw-semibold text-primary small-width">
                    {contact.first_name}
                  </div>
                  <div className="table-cell fw-semibold text-primary small-width">
                    {contact.last_name}
                  </div>
                  <div className="table-cell fw-semibold text-primary">
                    {contact.email || "n/a"}
                  </div>
                  <div className="table-cell fw-semibold text-primary">
                    {contact.company || "n/a"}
                  </div>
                  <div className="table-cell fw-semibold text-primary">
                    <span
                      className={`contact-type ${contact.type.color} badge`}
                      style={{ borderRadius: 20, padding: "7px 20px" }}
                    >
                      {contact.type.name}
                    </span>
                  </div>
                  <div className="table-cell d-flex gap-1">
                    <button
                      title={
                        contact.is_highlighted
                          ? "Unhighlight Contact"
                          : "Highlight Contact"
                      }
                      className={`btn btn-others icon-btn ${
                        contact.is_highlighted ? "active" : ""
                      }`}
                      style={{ borderRadius: 50 }}
                      onClick={() => {
                        dispatch(reset());
                        dispatch(highlighContact(contact._id));
                      }}
                    >
                      <FontAwesomeIcon icon={faStar} />
                    </button>
                    <button
                      title="Edit Contact"
                      className="btn btn-others icon-btn"
                      style={{ borderRadius: 50 }}
                      onClick={() => {
                        dispatch(reset());
                        setContactToEdit(contact._id);
                      }}
                    >
                      <FontAwesomeIcon icon={faPen} />
                    </button>
                    <button
                      title="Duplicate Contact"
                      className="btn btn-others icon-btn"
                      style={{ borderRadius: 50 }}
                      onClick={() => {
                        dispatch(reset());
                        setContactToDuplicate(contact._id);
                      }}
                    >
                      <FontAwesomeIcon icon={faClone} />
                    </button>
                    <button
                      title="Delete Contact"
                      className="btn icon-btn btn-others"
                      style={{ borderRadius: 50 }}
                      onClick={() => {
                        dispatch(reset());
                        setContactToDelete(contact._id);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </div>
                </div>
              ))}
          {!isLoading && contacts?.length === 0 && (
            <div className="text-center text-primary fw-semibold">
              <h3>No Data</h3>
            </div>
          )}
        </div>
      </div>

      {/* Add contact modal */}
      {ContactFormOpen && (
        <Modal classes="bg-secondary" onCloseModalClick={onCloseModalClick}>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              if (ContactToEdit) {
                dispatch(
                  updateContact({ id: ContactToEdit, data: ContactForm })
                );
              } else {
                dispatch(createContact(ContactForm));
              }
            }}
          >
            <div className="contact-modal support-plan-modal">
              <h1 className="text-primary" style={{ marginBottom: 5 }}>
                {ContactToEdit ? "Edit" : "New"} Contact
              </h1>
              <div className="d-flex gap-2">
                <div className="form-group w-100">
                  <label>First Name *</label>
                  <input
                    data-type="text"
                    value={ContactForm["first_name"]}
                    onChange={(event) => {
                      onMainInputChange("first_name", event.target.value);
                    }}
                    placeholder="First Name *"
                  />
                  {formErrors?.first_name && (
                    <span className="text-danger input-error">
                      {formErrors?.first_name}
                    </span>
                  )}
                </div>
                <div className="form-group w-100">
                  <label>Last Name *</label>
                  <input
                    data-type="text"
                    value={ContactForm["last_name"]}
                    onChange={(event) => {
                      onMainInputChange("last_name", event.target.value);
                    }}
                    placeholder="Last Name *"
                  />
                  {formErrors?.last_name && (
                    <span className="text-danger input-error">
                      {formErrors?.last_name}
                    </span>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>Mobile Number *</label>
                <input
                  value={ContactForm["mobile_number"]}
                  onChange={(event) => {
                    onMainInputChange("mobile_number", event.target.value);
                  }}
                  data-type="text"
                  placeholder="Mobile Number *"
                />
                {formErrors?.mobile_number && (
                  <span className="text-danger input-error">
                    {formErrors?.mobile_number}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label>Email Address</label>
                <input
                  value={ContactForm["email"]}
                  onChange={(event) => {
                    onMainInputChange("email", event.target.value);
                  }}
                  data-type="text"
                  placeholder="Email Address"
                />
                {formErrors?.email && (
                  <span className="text-danger input-error">
                    {formErrors?.email}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label>Type *</label>
                <Select
                  name="type"
                  styles={SelectStyles}
                  placeholder="Type *"
                  value={contactTypes
                    .map((type) => {
                      return { label: type.name, value: type._id };
                    })
                    .find((type) => type.value === ContactForm["type"])}
                  options={contactTypes.map((type) => {
                    return { label: type.name, value: type._id };
                  })}
                  onChange={(option) => {
                    onMainInputChange("type", option.value);
                  }}
                />
                {formErrors?.type && (
                  <span className="text-danger input-error">
                    {formErrors?.type}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label>Note</label>
                <textarea
                  placeholder="Note"
                  value={ContactForm["note"]}
                  onChange={(event) =>
                    onMainInputChange("note", event.target.value)
                  }
                  className="h-50 w-100"
                ></textarea>
              </div>
              {contactFields?.map((field, index) => {
                return (
                  <div key={index} className="form-group">
                    <label>{field.name}</label>
                    <input
                      value={ContactForm[field.key]}
                      onChange={(event) => {
                        onMainInputChange(field.key, event.target.value);
                      }}
                      data-type="text"
                      placeholder={field.name}
                    />
                  </div>
                );
              })}
              <div className="d-flex" style={{marginTop: 10}}>
                <button
                  disabled={isFormLoading}
                  className="btn btn-lg btn-primary mx-auto w-100"
                  type="submit"
                >
                  <h4>{isFormLoading ? "Loading..." : "Save"}</h4>
                </button>
              </div>
            </div>
          </form>
        </Modal>
      )}

      {/* Delete Contact Modal */}
      {ContactToDelete && (
        <Modal
          classes="bg-primary"
          onCloseModalClick={() => {
            setContactToDelete(null);
          }}
        >
          <div className="delete-contact-modal text-center">
            <h3 className="text-primary">
              Are you sure you want to delete this contact?
            </h3>
            <button
              onClick={() => {
                dispatch(deleteContact(ContactToDelete));
              }}
              className="btn btn-lg btn-danger mx-auto"
            >
              <h3>Delete</h3>
            </button>
          </div>
        </Modal>
      )}
    </>
  );
}

export default Contacts;

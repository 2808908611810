import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../utils/api";
import { fetchServers } from "./ServersSlice";
import { toast } from "react-toastify"; // Importing toastify

// Fetch all projects
export const fetchWebsites = createAsyncThunk(
    "websites/fetchWebsites",
    async (_, { dispatch }) => {
        try {
            const response = await api.get("/websites", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            toast.success("Websites fetched successfully!"); // Success toast
            return response.data;
        } catch (error) {
            console.error("Error fetching projects:", error);
            toast.error("Failed to fetch websites!"); // Error toast
            throw error;
        }
    }
);

// Create a new project
export const createProject = createAsyncThunk(
    "websites/createProject",
    async (newProject, { dispatch }) => {
        try {
            const response = await api.post("/websites", newProject, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            dispatch(fetchServers());
            toast.success("Project created successfully!"); // Success toast
            return response.data;
        } catch (error) {
            console.error("Error creating project:", error);
            toast.error("Failed to create project!"); // Error toast
            throw error;
        }
    }
);

// Update an existing project
export const updateProject = createAsyncThunk(
    "websites/updateProject",
    async ({ id, updatedProject }, { dispatch }) => {
        try {
            const response = await api.put(`/websites/${id}`, updatedProject, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            dispatch(fetchServers());
            toast.success("Project updated successfully!"); // Success toast
            return response.data;
        } catch (error) {
            console.error("Error updating project:", error);
            toast.error("Failed to update project!"); // Error toast
            throw error;
        }
    }
);

// Delete a project
export const deleteProject = createAsyncThunk(
    "websites/deleteProject",
    async (id, { dispatch }) => {
        try {
            await api.delete(`/websites/${id}`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            dispatch(fetchServers());
            toast.success("Project deleted successfully!"); // Success toast
        } catch (error) {
            console.error("Error deleting project:", error);
            toast.error("Failed to delete project!"); // Error toast
            throw error;
        }
    }
);

const websitesSlice = createSlice({
    name: "websites",
    initialState: {
        projects: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchWebsites.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchWebsites.fulfilled, (state, action) => {
                state.projects = action.payload;
                state.loading = false;
            })
            .addCase(fetchWebsites.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(createProject.fulfilled, (state, action) => {
                state.projects.push(action.payload); // Add new project to state
            })
            .addCase(deleteProject.fulfilled, (state, action) => {
                state.projects = state.projects.filter((project) => project._id !== action.meta.arg); // Remove project after deletion
            })
            .addCase(updateProject.fulfilled, (state, action) => {
                const index = state.projects.findIndex((project) => project._id === action.payload._id);
                if (index !== -1) {
                    state.projects[index] = action.payload; // Update project data
                }
            });
    },
});

export const selectWebsites = (state) => state.websites.projects;
export const selectLoading = (state) => state.websites.loading;
export const selectError = (state) => state.websites.error;

export default websitesSlice.reducer;

import { Link } from "react-router-dom";

function Error({ code }) {
  const errorMessage =
    code === 404
      ? "Resource not found"
      : code === 403
      ? "You are not allowed to access this resource"
      : "An unexpected error occurred";

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        gap: "10px",
        backgroundColor: "white",
        border: "1px solid #EAECF0",
        borderRadius: "10px",
      }}
    >
      <div style={{ position: "relative" }}>
        <h1
          style={{
            fontSize: "18rem",
            color: "#007bff30",
            fontWeight: "bold",
          }}
        >
          {code}
        </h1>
        <h1
          style={{
            fontSize: "4rem",
            color: "#333333",
            fontWeight: "bold",
            position: "absolute",
            bottom: 50,
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          ERROR
        </h1>
      </div>

      <h3
        style={{
          color: "#6c757d",
          fontWeight: "500",
          marginBottom: "30px",
          marginTop: -25,
        }}
      >
        {errorMessage}
      </h3>
      <Link
        to="/"
        style={{
          textDecoration: "none",
          backgroundColor: "#007bff",
          color: "#fff",
          padding: "15px 30px",
          borderRadius: "8px",
          fontSize: "1rem",
        }}
      >
        Back to Dashboard
      </Link>
    </div>
  );
}

export default Error;

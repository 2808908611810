import axios from "axios";

const api = axios.create({
  baseURL: 'https://fitchr.com',
  // baseURL: 'http://localhost:5000',
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Credentials": true,
  },
});

export default api;
